import React from 'react';
import { chunk } from 'lodash';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';

export const GridContainer = ({ children, cols }) => {
  const rows = chunk(React.Children.toArray(children), cols);
  const colWidth = 12 / cols;

  return (
    <Grid className='container'>
      {rows.map((columns) => (
        <Row key={columns.key}>
          {columns.map((col) => (
            <Col key={col.key} md={colWidth}>
              {col}
            </Col>
          ))}
        </Row>
      ))}
    </Grid>
  );
};

GridContainer.defaultProps = {
  cols: 3,
};

GridContainer.propTypes = {
  cols: PropTypes.number,
  children: PropTypes.node.isRequired,
};
