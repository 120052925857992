import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../../../assets/styles/previewprogram.scss';

export const ErrorMessage = ({ displayIndication, displayMessage }) => (
  <div className='error-banner-container'>
    <span>{displayMessage}</span>
    {displayIndication}
    <div>
      <Link to='/preview' className='link'>
        Click here
      </Link>{' '}
      to return back to the Voice of the Customer Portal.
    </div>
  </div>
);

ErrorMessage.propTypes = {
  displayMessage: PropTypes.string.isRequired,
  displayIndication: PropTypes.string.isRequired,
};
