export const getReleaseFilesQuery = `
    query getPreReleaseById($id: ID!) {
      Release(id: $id) {
        data {
          id
          date
          type
          builds {
            data {
              id
              productPlatformArchitecture {
                data {
                  description
                  architecture {
                    data {
                      name
                      internalName
                    }
                  }
                  productPlatform {
                    data {
                      platform {
                        data {
                          name
                          internalName
                          operatingSystem {
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            data {
              files {
                data {
                  filename
                  links {
                    downloadLink
                    md5
                  }
                }
              }
            }
          }
        }
      }
    }
`;