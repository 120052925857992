/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';

export const AllowRedirect = (Component) => {
  const RedirectWrapper = ({ programsDetail, ...rest }) => {
    const { program_redirect_url: programRedirectUrl } = programsDetail || {};

    const { sendFeedback } = queryString.parse(window.location.search);

    if (sendFeedback) {
      return <Component {...rest} />;
    }

    if (programRedirectUrl) {
      window.location.href = programRedirectUrl;
      return null;
    }

    return <Component {...rest} />;
  };

  RedirectWrapper.propTypes = {
    programsDetail: PropTypes.shape({}).isRequired,
  };

  const mapStateToProps = ({ programsDetail }) => ({ programsDetail });

  return connect(mapStateToProps)(RedirectWrapper);
};
