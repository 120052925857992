export default {
  localeMapping: {
    en: 'en_us',
  },
  app: {
    defaultLocale: 'en',
  },
  pages: {
    LANDING: 0,
  },
  vidyard: {
    url: 'https://play.vidyard.com',
  },
  programsWithoutBrowser: ['splunkvr', 'gaby_program'],
  featureFlags: {},
};
