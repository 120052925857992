import { useLocation, withRouter } from 'react-router-dom';
import { useEffect } from 'react';

function _ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const ScrollToTop = withRouter(_ScrollToTop);
