import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab } from './Tab/Tab';
import './tabs.scss';

export const Tabs = ({ children, defaultTab }) => {
  const [currentContent, setCurrentContent] = useState(defaultTab);

  const isCurrentTab = (key) => key === currentContent;

  const tabsObj = Object.entries(children)
    .map(([key, value]) => {
      //from a single children element
      if (key === 'props') {
        return {
          tabKey: value.tabKey,
          label: value.label,
          icon: value.icon,
          children: value.children,
          link: value.link,
        };
      }
      //from array of child elements
      else if (!isNaN(key)) {
        return {
          tabKey: value.props.tabKey,
          label: value.props.label,
          icon: value.props.icon,
          children: value.props.children,
          link: value.props.link,
        };
      }
    })
    .filter((tab) => tab);

  const renderCurrentContent = tabsObj?.find((x) => x.tabKey === currentContent).children;

  const handleClick = (e, value) => {
    if (value.link) {
      e.preventDefault();
      window.location.replace(value.link);
    } else setCurrentContent(value.tabKey);
  }
  return (
    <div className='tabs-container'>
      <div className='tabs'>
        {tabsObj.map((value) => (
          <button
            className={isCurrentTab(value.tabKey) ? 'active' : undefined}
            type='button'
            key={value.tabKey}
            onClick={(e) => handleClick(e, value)}
          >
            {/* {value.icon} */}
            {value.label}
          </button>
        ))}
        <div className='content'>{renderCurrentContent}</div>
      </div>
    </div>
  );
};

//add validation of the specific instance for Tab children component
PropTypes.equalTo = function (component) {
  return function validate(propValue, key, componentName, location, propFullName) {
    const prop = propValue[key];
    if (prop.type !== component) {
      return new Error(
        'Invalid prop `' +
        propFullName +
        '` supplied to' +
        ' `' +
        componentName +
        '`. Validation failed.'
      );
    }
  };
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.equalTo(Tab)), PropTypes.equalTo(Tab)])
    .isRequired,
  defaultTab: PropTypes.number.isRequired,
};
