import React from "react";

import { Button, Checkbox, Form, Modal } from 'react-bootstrap';
import { useQuery } from 'react-query'

import '../assets/styles/survey.css';
import surveyStatusService from '../services/survey-status/survey-status.service';
import usePopupSurvey from "../utils/popupSurvey/usePopupSurvey";

const SurveyPopUp = () => {
  const [openPopup, setOpenPopup] = React.useState(true);

  const [showMessage, setShowMessage] = React.useState(false)

  const surveySeason = useQuery([], () => surveyStatusService())?.data?.surveySeason;

  const { showSurveyCookie, setSurveyCookie } = usePopupSurvey();

  const noThanksClick = () => {
    setSurveyCookie(showMessage);
    setOpenPopup(false);
  }

  const letsGoClick = () => {
    setSurveyCookie(true);
    setOpenPopup(false);
    window.open(process.env.REACT_APP_QUALTRICS_URL || 'https://splunk.sjc1.qualtrics.com/jfe/form/SV_damsNqICIioBqjs');
  }

  const showModal = openPopup && surveySeason && showSurveyCookie

  return (
    <Modal
      show={showModal}
      backdrop='static'
      keyboard={false}
      centered='true'
      className='survey-pop-up'

    >

      <Modal.Header>
        <div className='survey-title'>Help us to improve!</div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className='survey-body'>
            <div className='survey-body-message'>Take a 1 minute survey</div>

            <div className='survey-buttons'>
              <Button variant='secondary' onClick={noThanksClick}>
                Not now
              </Button>
              <Button variant='primary' onClick={letsGoClick}>Lets Go!</Button>
            </div>
            <div className='survey-checkbox'>
              <Checkbox checked={showMessage} onChange={() => setShowMessage(prev => !prev)}>
                Don&apos;t show this message again
              </Checkbox>
            </div>
          </div>
        </Form>

      </Modal.Body>
    </Modal>
  )
}

export default SurveyPopUp;