'use strict';

import { create } from 'apisauce';
import { create as axiosCreate } from 'axios';

import { store } from 'src/store/configureStore';

// temp-workaround: use this endpoint to run UI standalone with stg infra
// export const APP_BASE_URI = 'https://pre-release.staging.splunk.com'

// Default API root is same server, same protocol
export const API_ROOT = `/api/v2/pre-release`;

export const axiosApi = axiosCreate({
  baseURL: API_ROOT,
})

export const api = create({
  baseURL: API_ROOT,
  axiosInstance: axiosApi,
});

const intercept = async (config) => {
  const { token } = store.getState().auth;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

axiosApi.interceptors.request.use(
  intercept,
  (error) => {
    return Promise.reject(error)
  },
);

api.axiosInstance.interceptors.request.use(
  intercept,
  (error) => {
    return Promise.reject(error)
  },
);
