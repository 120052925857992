import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ThankYou from '../components/Thankyou';
import LoadingScreen from '../components/common/LoadingScreen';

import useFetch from '../utils/useFetch';

const decodeUrlParams = (url) => url.replace(/&amp;/g, '&');

const ThankYouContainer = ({ programsDetail }) => {
  const { program_key } = programsDetail;

  const { loading, data } = useFetch(
    `programs/${program_key}/download${decodeUrlParams(location.search)}`
  );

  if (loading) return <LoadingScreen />;

  return (
    <div>
      <ThankYou programsDetail={programsDetail} downloads={data.downloads} params={data.params} />
    </div>
  );
};

ThankYouContainer.propTypes = {
  programsDetail: PropTypes.shape({
    program_key: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ programsDetail }) => ({ programsDetail });

export default connect(mapStateToProps)(ThankYouContainer);
