import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreenPresentational } from '../components/common/LoadingScreen';
import { parse, stringify } from 'query-string';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { get } from 'lodash';
import jwtDecode from 'jwt-decode';
import { fromUnixTime, isFuture, isPast } from 'date-fns';
import { SET_AUTH_IS_LOADING, SET_AUTH_TOKEN, SET_CURRENT_USER } from 'src/constants/actionTypes';
import { redirectToLogin } from 'src/helpers/redirect';

const AuthContainer: React.FC = ({ children }) => {
  const history = useHistory();
  const isLoading = useSelector((state: any) => state.auth.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = parse(window.location.search);
    const queryJwtToken = get(query, 'jwtToken')?.toString()

    if(queryJwtToken) {
      localStorage.setItem('pre_release_ui_token', queryJwtToken);

      const {...queryRest} = query
      history.push({
        search: stringify(queryRest)
      })
    }

    const jwtToken = localStorage.getItem('pre_release_ui_token')

    if(jwtToken) {
      try {
        const jwtPayload: any = jwtDecode(jwtToken);
        const expirationDate = fromUnixTime(jwtPayload.exp);

        if(isFuture(expirationDate)) {
          dispatch({
            type: SET_AUTH_TOKEN,
            payload: jwtToken,
          })

          const [firstName, ...rest] = jwtPayload.user.name.split(/\s/)

          dispatch({
            type: SET_CURRENT_USER,
            payload: {
              user: {
                firstName: firstName || '',
                lastName: rest?.join(' ') || '',
                email: jwtPayload.user.email,
                username: jwtPayload.user.username,
              }
            }
          })
        }

        if(isPast(expirationDate)) {
          throw new Error('Expired token')
        }
      } catch(error) {
        console.log(error)
        localStorage.removeItem('pre_release_ui_token')
      }

      dispatch({
        type: SET_AUTH_IS_LOADING,
        payload: false,
      })

      return
    }

    redirectToLogin()
  }, [])

  return (
    <div>{isLoading ? <LoadingScreenPresentational isLoading={isLoading} /> : <>{children}</>}</div>
  );
};

export default AuthContainer;
