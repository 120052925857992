import * as types from '../constants/actionTypes';
import { cloneDeep } from 'lodash';

import initialState from './initialState';

export default function productDownloadReducer(
  state = cloneDeep(initialState.productDownload),
  action
) {
  switch (action.type) {
    case types.FETCH_PRODUCT_DOWNLOAD_REQUEST_SUCCESS:
      return action.data.data;

    case types.FETCH_PRODUCT_DOWNLOAD_REQUEST_FAILURE:
      return state;

    default:
      return state;
  }
}
