import jwtDecode from 'jwt-decode';

export type AuthJwtPayload = {
  exp?: number;
  user: {
    name: string;
    email: string;
    username: string;
  }
};

export const parseAuthJwt = async (jwtToken: string): Promise<AuthJwtPayload> => {
  try {
    const decoded: Record<string, any> = jwtDecode(jwtToken);

    return {
      exp: decoded.exp,
      user: {
        name: decoded.user.name,
        email: decoded.user.email,
        username: decoded.user.username,
      }
    };
  } catch (error) {
    throw new Error('Unable to parse JWT');
  }
};
