import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from './Breadcrumbs';
import ThankyouHeader from './ThankyouHeader';
import ThankyouContent from './ThankyouContent';

import '../../assets/styles/style.scss';
import '../../assets/styles/previewprogram.scss';

const downloadFile = (link) => {
  if (link) window.location = link;
};

const ThankYou = ({ programsDetail, params, downloads }) => {
  const { program_key, display_name, content } = programsDetail;
  const { product, platform, version } = params;
  const { download_home_url, wget, md5_link } = downloads;

  useEffect(() => {
    downloadFile(download_home_url);
  }, []);

  return (
    <div id='content'>
      <div className='pre-release-content'>
        <ThankyouHeader
          product={product}
          platform={platform}
          version={version}
          productLink={download_home_url}
          md5Link={md5_link}
          wgetLink={wget}
        />
        <Breadcrumbs program_key={program_key} displayName={display_name} />
        <ThankyouContent displayName={display_name} content={content} />
      </div>
    </div>
  );
};

ThankYou.defaultProps = {
  downloads: {
    download_home_url: '',
    wget: '',
    md5_link: '',
  },
  params: {
    product: '',
    platform: '',
    version: '',
  },
  programsDetail: {
    program_key: '',
    display_name: '',
  },
};

ThankYou.propTypes = {
  downloads: PropTypes.shape({
    download_home_url: PropTypes.string,
    md5_link: PropTypes.string,
    wget: PropTypes.string,
  }),
  params: PropTypes.shape({
    product: PropTypes.string,
    platform: PropTypes.string,
    version: PropTypes.string,
  }),
  programsDetail: PropTypes.shape({
    program_key: PropTypes.string,
    display_name: PropTypes.string,
    content: PropTypes.shape({
      document_link: PropTypes.string,
      release_notes_url: PropTypes.string,
      system_requirement: PropTypes.string,
    }).isRequired,
  }),
};

export default React.memo(ThankYou);
