import * as types from '../constants/actionTypes';
import { REQUEST, sendRequest } from '../utils/networkUtils';

export const fetchProgramList = (sortBy, sortDirection) => {
  return sendRequest(
    types.FETCH_PROGRAM,
    REQUEST.GET,
    `/programs?program_status_code=2${
      sortBy && sortDirection ? `&sort_by=${sortBy}&sort_direction=${sortDirection}` : ''
    }`
  );
};

export const fetchProgramDetail = (key) => {
  return sendRequest(types.FETCH_PROGRAM_DETAIL, REQUEST.GET, '/programs/' + key);
};

export const clearProgramKey = () => ({
  type: types.CLEAR_PROGRAM_KEY,
});
