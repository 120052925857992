import React from 'react';
import PropTypes from 'prop-types';

const DocumentationFooter = ({ document_link, release_notes_url, system_requirement }) => (
  <div className='row other-links pull-right'>
    {document_link && (
      <a className='doc-footer-link' href={document_link} target='_blank' rel='noopener noreferrer'>
        {' '}
        Documentation{' '}
      </a>
    )}
    {release_notes_url && (
      <a
        className='doc-footer-link'
        href={release_notes_url}
        target='_blank'
        rel='noopener noreferrer'
      >
        {' '}
        Release Notes{' '}
      </a>
    )}
    {system_requirement && (
      <a
        className='doc-footer-link'
        href={system_requirement}
        target='_blank'
        rel='noopener noreferrer'
      >
        {' '}
        System Requirements{' '}
      </a>
    )}
  </div>
);

DocumentationFooter.defaultProps = {
  document_link: '',
  release_notes_url: '',
  system_requirement: '',
};

DocumentationFooter.propTypes = {
  document_link: PropTypes.string,
  release_notes_url: PropTypes.string,
  system_requirement: PropTypes.string,
};

export default DocumentationFooter;
