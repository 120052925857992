const validator = {};

validator.validate = (fieldName, value) => {
  let formFieldError, cssClass;
  let isValid = false;
  switch (fieldName) {
    case 'title':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : 'Required';
      cssClass = isValid ? 'success' : 'error';
      break;
    case 'importanceFB':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : 'Required';
      cssClass = isValid ? 'success' : 'error';
      break;
    case 'describeFeature':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : 'Required';
      cssClass = isValid ? 'success' : 'error';
      break;
    case 'bugTitle':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : 'Required';
      cssClass = isValid ? 'success' : 'error';
      break;
    case 'bugDescribeFeature':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : 'Required';
      cssClass = isValid ? 'success' : 'error';
      break;
    case 'stepsToReproduce':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : '';
      cssClass = isValid ? 'success' : '';
      break;
    case 'priority':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : '';
      cssClass = isValid ? 'success' : '';
      break;
    case 'browser':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : '';
      cssClass = isValid ? 'success' : '';
      break;
    case 'platform':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : '';
      cssClass = isValid ? 'success' : '';
      break;
    case 'platformVersion':
      isValid = value != null && value != '' && value != undefined ? true : false;
      formFieldError = isValid ? 'Good' : '';
      cssClass = isValid ? 'success' : '';
      break;
    default:
      isValid = true;
      formFieldError = '';
      cssClass = '';
      break;
  }
  return { isValid, formFieldError, cssClass };
};
export default validator;
