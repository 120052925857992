import * as types from '../constants/actionTypes';
import { REQUEST, sendRequest } from '../utils/networkUtils';

export const fetchJiraPriorities = () => {
  return sendRequest(types.FETCH_JIRA_PRIORITIES_REQUEST, REQUEST.GET, '/jira/priorities');
};

export const fetchJiraPlatforms = () => {
  return sendRequest(types.FETCH_JIRA_PLATFORM_REQUEST, REQUEST.GET, '/release/platforms');
};
