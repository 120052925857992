import { cloneDeep, get } from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';

export function formatUser(username) {
  const name = username.includes(' (C)') ? username.replace(' (C)', '') : username;
  const splitName = name.split(' ');

  const firstName = splitName[0];
  const lastName = splitName[splitName.length - 1];

  return [firstName, lastName];
}

export default function authReducer(state = cloneDeep(initialState.auth), action) {
  switch (action.type) {
    case types.SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      }
    }

    case types.SET_AUTH_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }

    case types.FETCH_USER_AUTHENTICATION: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.FETCH_USER_AUTHENTICATION_SUCCESS: {
      const user = get(action.data.data, 'user');

      const [firstName, lastName] = formatUser(user.name);

      return {
        ...state,
        ...action.data,
        loading: false,
        user: {
          firstName,
          lastName,
          username: user.username,
          email: user.email,
        },
      };
    }

    case types.SET_CURRENT_USER: {
      const user = get(action.payload, 'user')

      return {
        ...state,
        user: {
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          email: user.email,
        }
      }
    }

    case types.FETCH_USER_AUTHENTICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
