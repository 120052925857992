import jira from 'src/constants/jira';

export default {
  title: '',
  importanceFB: '',
  describeFeature: '',
  bugTitle: '',
  selectedFeedbackType: jira.issueTypes.bug,
  bugDescribeFeature: '',
  stepsToReproduce: '',
  priority: '',
  browser: '',
  platform: '',
  platformVersion: '',
  formErrors: {
    title: '',
    importanceFB: '',
    describeFeature: '',
    bugTitle: '',
    bugDescribeFeature: '',
    stepsToReproduce: '',
    priority: '',
    browser: '',
    platform: '',
    platformVersion: '',
    feedback_type: '',
  },
  cssClass: {
    title: '',
    importanceFB: '',
    describeFeature: '',
    bugTitle: '',
    bugDescribeFeature: '',
    stepsToReproduce: '',
    priority: '',
    browser: '',
    platform: '',
    platformVersion: '',
  },
  isValid: {
    title: false,
    importanceFB: false,
    describeFeature: false,
    bugTitle: false,
    bugDescribeFeature: false,
    stepsToReproduce: false,
    priority: false,
    browser: false,
    platform: false,
    platformVersion: false,
  },
  isFeatureReportFormValid: false,
  isBugReportFormValid: false,
  visible: false,
  activeTab: {
    name: '',
    key: null,
    openedAt: 0,
  },
  platformVersions: [],
  showFeatureThankyouFeedback: false,
  showBugThankyouFeedback: false,
  bugAttachments: [],
  featureAttachments: [],
  modalDialogClassName: '',
};
