import React, { useState } from 'react';
import t from 'prop-types';
import { useQuery } from 'urql';
import { AccordionHeader } from './AccordionHeader';
import { PackageLinkTable } from './PackageLinkTable';
import { getReleaseFilesQuery } from './releaseQuery';
import { formatPublishDate } from '../../utils/dateHelper';
import { logActivity } from '../../utils/userActivitiesService';
import { groupBy, map, get, keys } from 'lodash';
import clsx from 'clsx';

const DownloadFooter = ({ buildsId, release }) => {
  const [activeTab, setActiveTab] = useState(0);

  const [result] = useQuery({
    query: getReleaseFilesQuery,
    variables: {
      id: release.id,
    },
  });

  const { data, fetching, error } = result;

  if (fetching || error) return null;

  const builds = data?.data?.Release?.data?.builds?.data;

  const filteredBuilds = builds?.filter((build) => buildsId.includes(build.id)) || [];

  const releaseType = data?.data?.Release?.data?.type || '';

  const publishDate = data?.data?.Release?.data?.date || '';

  const groupedBuilds = groupBy(
    filteredBuilds,
    'productPlatformArchitecture.data.productPlatform.data.platform.data.internalName'
  );

  const displayTab = activeTab || get(keys(groupedBuilds), '0');

  const trackDownload = async (file, platform, architecture, release) => {
    const payload = {
      file,
      platform,
      architecture,
      version: release.version,
      product: release.product,
    };

    await logActivity(payload);
  };
  return (
    <div className='row expresslane-download-list'>
      <div className='content'>
        <div>
          <div>
            <div>
              <b />
            </div>
            <AccordionHeader
              groupedBuilds={groupedBuilds}
              onChange={setActiveTab}
              activeTab={displayTab}
            />
            <div className='tab-content'>
              {map(groupedBuilds, (buildGroup, key) => {
                const groupedArchitectures = groupBy(
                  buildGroup,
                  'productPlatformArchitecture.data.architecture.data.internalName'
                );

                return (
                  <div
                    role='tabpanel'
                    className={clsx('tab-pane', {
                      active: key === displayTab,
                    })}
                    key={`tab-${key}`}
                    id={key}
                  >
                    {map(groupedArchitectures, (builds) => {
                      const architecture = get(
                        builds[0],
                        'productPlatformArchitecture.data.architecture.data'
                      );

                      return (
                        <div key={`item-${key}-${architecture.internalName}`}>
                          <div className='container-fluid'>
                            {map(builds, (build) => {
                              const description = get(
                                build,
                                'productPlatformArchitecture.data.description'
                              );
                              const platform = get(
                                build,
                                'productPlatformArchitecture.data.productPlatform.data.platform.data.name'
                              );
                              return (
                                <div
                                  className='row version-block'
                                  key={`item-${key}-${architecture.internalName}-row`}
                                >
                                  <div className='col-xs-3 col-sm-2 col-md-2 col-lg-2 padding bits'>
                                    <span className='pill'>{architecture.name}</span>
                                  </div>
                                  <div className='col-xs-9 col-sm-4 col-md-4 col-lg-4 padding'>
                                    <h4 className='splunk2-h4 release-date-title'>
                                      {`${formatPublishDate(publishDate)} - ${releaseType}`}
                                    </h4>
                                    {description && (
                                      <span
                                        className='strong'
                                        dangerouslySetInnerHTML={{ __html: description }}
                                      />
                                    )}
                                  </div>
                                  {build.files && (
                                    <PackageLinkTable
                                      packages_links={build.files.data}
                                      trackDownload={(fileName) =>
                                        trackDownload(
                                          fileName,
                                          platform,
                                          architecture.name,
                                          release
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DownloadFooter.propTypes = {
  release: t.shape({
    id: t.string.isRequired,
    version: t.string.isRequired,
    product: t.string.isRequired,
  }),
  buildsId: t.arrayOf(t.string).isRequired,
};

export default DownloadFooter;
