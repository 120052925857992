import React from 'react';
import PropTypes from 'prop-types';
import DocumentationFooter from '../DocumentationFooter';

const ThankyouContent = ({ displayName, content }) => (
  <div className='splunk-padding'>
    <div className='container'>
      <div className='row'>
        <div className='col-sm-12 col-md-9'>
          <div className='splunk-body'>
            <h2 className='splunk2-h3'>Thank you for downloading!</h2>
            <p>
              Now that you have downloaded {displayName}, we want you to use it to its full
              potential. The resources below will help you get off to a quick start.
            </p>
          </div>
        </div>
      </div>
      <DocumentationFooter
        document_link={content.document_link}
        release_notes_url={content.release_notes_url}
        system_requirement={content.system_requirement}
      />
    </div>
  </div>
);

ThankyouContent.propTypes = {
  displayName: PropTypes.string.isRequired,
  content: PropTypes.shape({
    document_link: PropTypes.string,
    release_notes_url: PropTypes.string,
    system_requirement: PropTypes.string,
  }).isRequired,
};

export default ThankyouContent;
