import { createClient } from 'urql';
import { get } from 'lodash'
import { store } from '../store/configureStore'

export const client = createClient({
  url: `/api/v2/pre-release/releases/graphql`,
  fetchOptions: () => {
    const token = get(store.getState(), 'auth.token')

    return {
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    };
  },
});
