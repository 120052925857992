import * as types from '../constants/actionTypes';
import { cloneDeep } from 'lodash';

import FeedbackInitialState from './feedbackInitialState';

const jiraReducer = {};

jiraReducer.jiraPrioritiesReducer = (state = cloneDeep(FeedbackInitialState), action) => {
  switch (action.type) {
    case types.FETCH_JIRA_PRIORITIES_REQUEST_SUCCESS:
      return action.data.data;

    case types.FETCH_JIRA_PRIORITIES_REQUEST_FAILURE:
      return state;

    default:
      return state;
  }
};

jiraReducer.jiraPlatformsReducer = (state = cloneDeep(FeedbackInitialState), action) => {
  switch (action.type) {
    case types.FETCH_JIRA_PLATFORM_REQUEST_SUCCESS:
      return action.data.data;

    case types.FETCH_JIRA_PLATFORM_REQUEST_FAILURE:
      return state;

    default:
      return state;
  }
};

export default jiraReducer;
