import React, { ChangeEvent, FocusEvent, FormEvent, MouseEvent } from 'react';
import { ProgramDetail } from 'src/types/program';

import UploadAttachment from './UploadAttachment';
import jira from '../../constants/jira';

interface FeedbackReportTabProps {
  bugTitle: string;
  bugDescribeFeature: string;
  cssClass: {
    browser: string;
    bugDescribeFeature: string;
    bugTitle: string;
    describeFeature: string;
    importanceFB: string;
    platform: string;
    platformVersion: string;
    priority: string;
    stepsToReproduce: string;
    title: string;
  };
  formErrors: {
    bugTitle: string;
    importanceFB: string;
    bugDescribeFeature: string;
    priority: string;
    platformVersion: string;
    platform: string;
    browser: string;
    stepsToReproduce: string;
    feedback_type: string;
  };
  isBugReportFormValid: boolean;
  programsDetail: ProgramDetail;
  handleAttachmentUpload: (type: string, content: any) => void;
  handleBugSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleFieldFocus: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleUserInput: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleFeedbackTypeChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onClose: (event: MouseEvent<HTMLButtonElement>) => void;
}

const FeedbackReportTab = ({
  bugTitle,
  bugDescribeFeature,
  cssClass,
  formErrors,
  isBugReportFormValid,
  programsDetail,
  handleAttachmentUpload,
  handleBugSubmit,
  handleFieldFocus,
  handleUserInput,
  handleFeedbackTypeChange,
  onClose,
}: FeedbackReportTabProps) => {
  const { program_key } = programsDetail;
  return (
    <form className='splunk-form' onSubmit={handleBugSubmit}>
      <div className='bug-field'>
        <span className='label-text required'>Feedback Type</span>
        <label htmlFor='feedbackType' className={`splunk-input input-select ${cssClass.platform}`}>
          <span className='feedback'>{formErrors.feedback_type}</span>
          <div className='input-wrap'>
            <select
              className='splunk-custom-select exlane-fields'
              name='feedbackType'
              id='feedbackType'
              onChange={handleFeedbackTypeChange}
              defaultValue={jira.issueTypes.bug}
            >
              <option value={jira.issueTypes.bug}>Bug</option>
              <option value={jira.issueTypes.story}>Enhancement Request</option>
            </select>
          </div>
        </label>
      </div>
      <div className='bug-field'>
        <span className='label-text required'>Subject</span>
        <label htmlFor='bugTitle' className={`splunk-input input-title ${cssClass.bugTitle}`}>
          <span className='feedback'>{formErrors.bugTitle}</span>
          <div className='input-wrap'>
            <input
              id='bugTitle'
              type='text'
              className='exlane-fields'
              name='bugTitle'
              value={bugTitle}
              onFocus={handleFieldFocus}
              onChange={handleUserInput}
            />
          </div>
        </label>
      </div>
      <div className='bug-field'>
        <span className='label-text required'>Description</span>
        <span className='description-template-text'>
          Describe in detail your feedback, providing details such as what screen you were on, what
          were you exactly doing, what values you were using, etc. Anything that maybe helpful in
          helping us identify/ clarify the feedback you are sharing.
        </span>

        <label
          htmlFor='bugDescribeFeature'
          className={`splunk-input input-title ${cssClass.bugDescribeFeature}`}
        >
          <span className='feedback'>{formErrors.bugDescribeFeature}</span>
          <div className='input-wrap'>
            <textarea
              id='bugDescribeFeature'
              name='bugDescribeFeature'
              value={bugDescribeFeature}
              onFocus={handleFieldFocus}
              onChange={handleUserInput}
              style={{ resize: 'none' }}
            />
          </div>
        </label>
      </div>
      <div className='attach-wrap'>
        <UploadAttachment
          programKey={program_key}
          setAttachment={handleAttachmentUpload}
          type='bug'
        />
      </div>
      <div className='white-background-buttons'>
        <button
          type='submit'
          className='btn-submit splunk-btn sp-btn-black-hollow'
          disabled={!isBugReportFormValid}
        >
          Submit
        </button>
        <button type='button' className='close-button' onClick={onClose}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default FeedbackReportTab;
