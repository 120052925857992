import * as types from '../constants/actionTypes';
import initialState from './initialState';

import { cloneDeep, isBoolean } from 'lodash';

export default function appReducer(state = initialState.app, action) {
  switch (action.type) {
    case types.TOGGLE_HEADER_POPUP_FOR_USER: {
      const newState = cloneDeep(state);
      if (isBoolean(action.show)) {
        newState.showHeaderPopupForUser = action.show;
      } else {
        newState.showHeaderPopupForUser = !state.showHeaderPopupForUser;
      }
      return newState;
    }

    case types.CHANGE_CURRENT_PAGE: {
      const newState = cloneDeep(state);
      if (action.page) {
        newState.lastPage = newState.currentPage;
        newState.currentPage = action.page;
      }
      return newState;
    }

    default:
      return state;
  }
}
