import { BrowserRouter, Route, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import ProductContainer from './ProductContainer';
import ProductDetailContainer from './ProductDetailContainer';
import ThankYouContainer from './ThankYouContainer';
import { ScrollToTop } from '../utils/ScrollToTop';
import AuthContainer from './AuthContainer';
import CommonContainer from './CommonContainer';

import { EulaProtection } from './EulaProtection';
import { AllowRedirect } from './AllowRedirect';
import { withRedux } from 'src/hocs/with-redux';
import { withReactQuery } from 'src/hocs/with-react-query';
import { compose } from 'recompose';
import { withGraphql } from 'src/hocs/with-graphql';

const tagManagerArgs = {
  gtmId: 'GTM-TPV7TP',
};

TagManager.initialize(tagManagerArgs);

const Root = () => (
  <CommonContainer>
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <AuthContainer>
          <Switch>
            <Route
              path='/preview/:key'
              component={AllowRedirect(EulaProtection(ProductDetailContainer))}
            />
            <Route path='/page/download_track' component={EulaProtection(ThankYouContainer)} />
            <Route path='/thankyou' component={EulaProtection(ThankYouContainer)} />
            <Route path='/' component={ProductContainer} />
          </Switch>
        </AuthContainer>
      </Switch>
    </BrowserRouter>
  </CommonContainer>
);

export default compose(
  withRedux,
  withReactQuery,
  withGraphql,
)(Root);
