import { combineReducers } from 'redux';

import app from './appReducer';
import aemContentReducer from './aemContentReducer';
import authReducer from './authReducer';
import programReducer from './programReducer';
import programDetailReducer from './programDetailReducer';
import feedbackTypesReducer from './feedbackTypesReducer';
import feedbackFormDataReducer from './feedbackFormDataReducer';
import feedbackReducer from './feedbackReducer';
import loadingReducer from './loadingReducer';
import jiraReducer from './jiraReducer';
import productDownloadReducer from './productDownloadReducer';

export default combineReducers({
  app: app,
  isLoading: loadingReducer,
  aemContent: aemContentReducer,
  auth: authReducer,
  programs: programReducer,
  programsDetail: programDetailReducer,
  feedbackTypes: feedbackTypesReducer,
  feedback: feedbackReducer,
  feedbackFormData: feedbackFormDataReducer,
  jiraPriorities: jiraReducer.jiraPrioritiesReducer,
  jiraPlatforms: jiraReducer.jiraPlatformsReducer,
  productDownload: productDownloadReducer,
})
