import { Provider } from 'urql';
import { ReactHoc } from 'src/types/hocs';

import { client } from 'src/utils/releaseApiClient';

export const withGraphql: ReactHoc = (Component) => (props) =>
  (
    <Provider value={client}>
      <Component {...props} />
    </Provider>
  )
