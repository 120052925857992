import constants from '../constants';

export default {
  app: {
    locale: constants.app.defaultLocale,
    showHeaderPopupForUser: false,
    lastPage: constants.pages.LANDING,
    currentPage: constants.pages.LANDING,
  },
  aemContent: {
    header: {},
    footer: {},
  },
  programs: [],
  downloadLink: {},
  version: {},
  productDownload: {},
  sendFeedback: 'false',
  programsDetail: {
    end_date: null,
    content: {
      images: [],
    },
    pastPrograms: [],
    products: [
      {
        versions: [],
      },
    ],
    program_key: '',
    ideas_portal_url: null,
    error: {},
    status: 0,
  },
  auth: {
    isLoading: true,
    token: null,
    user: {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      content: {
        isGuest: true,
      },
    },
    loading: false,
    error: null,
  },
  feedbackRequest: {
    content: {
      description: null,
      steps_to_reproduce: null,
      severity: null,
      browser: null,
      platform: null,
      version: null,
      title: null,
    },
    contact_details: {
      display_name: null,
      account_name: null,
      email: null,
      crm_id: null,
    },
  },
};
