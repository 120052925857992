import * as types from '../constants/actionTypes';
import { cloneDeep } from 'lodash';

import FeedbackInitialState from './feedbackInitialState';

export default function feedbackTypesReducer(state = cloneDeep(FeedbackInitialState), action) {
  switch (action.type) {
    case types.FETCH_FEATURE_TYPES_REQUEST_SUCCESS:
      return action.data.data;

    case types.FETCH_FEATURE_TYPES_REQUEST_FAILURE:
      return state;

    default:
      return state;
  }
}
