/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';
import { connect, useSelector } from 'react-redux';
import constants from '../constants';
import LoadingScreen from '../components/common/LoadingScreen';
import { fetchAEMContent } from '../actions/commonActions';
import { parseAuthJwt } from 'src/helpers/parse-auth-jwt';
import { getHostUrl } from 'src/helpers/host-url';
import SurveyPopUp from '../components/SurveyPopUp';
import { SURVEY_POPUP_TIMER_IN_SECONDS } from '../constants/config';

const CommonContainer = ({ children, fetchAEMContent, locale, aemContent }) => {
  const hasAEMContent = window.localStorage.getItem('aem_Content:header_footer_');

  useEffect(() => {
    fetchAEMContent(locale);
  }, []);

  const content = isNull(hasAEMContent) ? aemContent : JSON.parse(hasAEMContent);
  const authToken = useSelector((state) => state.auth.token);
  const [canShow, setCanShow] = useState(false);

  useEffect(() => {
    if (content.header && authToken) {
      const setHeaderUser = async () => {
        try {
          const jwtPayload = await parseAuthJwt(authToken)
          const currentUserName = jwtPayload.user.name

          const firstLetter = currentUserName[0].toUpperCase();

          jQuery('.splunk-icon.icon-expanded-user-icon').remove();
          jQuery('.splunk-user-avatar').removeClass('hidden');
          jQuery('.splunk-user-avatar .loginUserNameIcon').text(firstLetter);
          jQuery('.loggedin-user-link.hidden').removeClass('hidden').show();
          jQuery('#account li:not(.loggedin-user-link)').addClass('hidden');
          jQuery('li.loggedin-user-link:last a').attr('href', '#')
          jQuery('li.loggedin-user-link:last a').on('click', () => {
            clearSessionStorage();
            localStorage.removeItem('pre_release_ui_token');
            deleteCookies(`${getHostUrl()}/api/bin/sso/logout?module=sso_logout`);
          })

        } catch (error) {
          console.log('error setting user header', error)
        }
      }

      setTimeout(setHeaderUser, 500)
    }
  }, [content.header, authToken]);

  useEffect(() => {
    const timer = setTimeout(() => setCanShow(true), SURVEY_POPUP_TIMER_IN_SECONDS * 1000);
    return () => clearTimeout(timer);
  }, [SURVEY_POPUP_TIMER_IN_SECONDS]);

  return (
    <div>
      <LoadingScreen />
      <div className='header' dangerouslySetInnerHTML={{ __html: content.header }} />
      {children}
      <div className='footer' dangerouslySetInnerHTML={{ __html: content.footer }} />
      {canShow ? <SurveyPopUp /> : <> </>}
    </div>
  );
};

CommonContainer.propTypes = {
  aemContent: PropTypes.shape({}),
  fetchAEMContent: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
};

CommonContainer.defaultProps = {
  aemContent: {},
};

const mapDispatchToProps = (dispatch) => ({
  fetchAEMContent: (locale) => dispatch(fetchAEMContent(locale)),
});

const mapStateToProps = (state) => {
  const {
    app: { locale },
    aemContent,
  } = state;
  return {
    locale: constants.localeMapping[locale],
    aemContent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonContainer);
