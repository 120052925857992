import { cloneDeep } from 'lodash';
import * as types from '../constants/actionTypes';

import initialState from './initialState';

export default function aemContentReducer(state = cloneDeep(initialState.aemContent), action) {
  switch (action.type) {
    case types.FETCH_AEM_CONTENT_SUCCESS: {
      const content = action.data.data;
      window.localStorage.setItem('aem_Content:header_footer_', JSON.stringify(content));
      return content;
    }
    case types.FETCH_AEM_CONTENT_FAILURE:
      return state;

    default:
      return state;
  }
}
