import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import VideoPlayerVidyard, {
  VideoPlayerVidyardDefaultProps,
  VideoPlayerVidyardProps,
} from './VideoPlayerVidyard';
import constants from '../../../constants';

const VideoModalPropTypes = {
  videoPlayerSettings: PropTypes.shape(VideoPlayerVidyardProps).isRequired,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

const VideoModalDefaultProps = {
  defaultOpen: false,
  videoPlayerSettings: VideoPlayerVidyardDefaultProps,
  title: '',
  description: '',
};

const VideoModalVidyard = ({ defaultOpen, title, description, videoPlayerSettings }) => {
  const [open, setOpen] = useState(defaultOpen);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const { videoId } = videoPlayerSettings;
  return (
    <div className='video-modal'>
      <div
        role='button'
        onKeyPress={openModal}
        tabIndex={0}
        onClick={openModal}
        className='video-modal-trigger'
      >
        <div className='media-cnt'>
          <a className='open-popup-link' title={`${title || videoId}`}>
            <figure className='video-figure' data-test-id={title}>
              <img
                id={`previewImage-${videoId}`}
                src={`${constants.vidyard.url}/${videoId}.jpg`}
                alt={`${title || videoId}`}
              />
              <div className='playcontainer'>
                <i className='glyphicon glyphicon-play-circle '>&nbsp;</i>
              </div>
            </figure>
          </a>
        </div>
      </div>
      <div className='video-text' data-testid='vidyard-video-info'>
        <strong>{title}</strong>
        <span>{description}</span>
      </div>
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Body>
          <VideoPlayerVidyard {...videoPlayerSettings} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

VideoModalVidyard.propTypes = VideoModalPropTypes;
VideoModalVidyard.defaultProps = VideoModalDefaultProps;

export default VideoModalVidyard;
