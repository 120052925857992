export const RESET_LOADING = 'RESET_LOADING';
export const TOGGLE_HEADER_POPUP_FOR_USER = 'TOGGLE_HEADER_POPUP_FOR_USER';
export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE';

export const FETCH_AEM_CONTENT = 'FETCH_AEM_CONTENT';
export const FETCH_AEM_CONTENT_SUCCESS = 'FETCH_AEM_CONTENT_SUCCESS';
export const FETCH_AEM_CONTENT_FAILURE = 'FETCH_AEM_CONTENT_FAILURE';

export const FETCH_PROGRAM = 'FETCH_PROGRAM';
export const FETCH_PROGRAM_SUCCESS = 'FETCH_PROGRAM_SUCCESS';
export const FETCH_PROGRAM_FAILURE = 'FETCH_PROGRAM_FAILURE';

export const FETCH_PROGRAM_DETAIL = 'FETCH_PROGRAM_DETAIL';
export const FETCH_PROGRAM_DETAIL_SUCCESS = 'FETCH_PROGRAM_DETAIL_SUCCESS';
export const FETCH_PROGRAM_DETAIL_FAILURE = 'FETCH_PROGRAM_DETAIL_FAILURE';

export const FETCH_USER_AUTHENTICATION = 'FETCH_USER_AUTHENTICATION';
export const FETCH_USER_AUTHENTICATION_SUCCESS = 'FETCH_USER_AUTHENTICATION_SUCCESS';
export const FETCH_USER_AUTHENTICATION_FAILURE = 'FETCH_USER_AUTHENTICATION_FAILURE';

export const CREATE_FEATURE_REQUEST = 'CREATE_FEATURE_REQUEST';
export const CREATE_FEATURE_REQUEST_SUCCESS = 'CREATE_FEATURE_REQUEST_SUCCESS';
export const CREATE_FEATURE_REQUEST_FAILURE = 'CREATE_FEATURE_REQUEST_FAILURE';

export const FETCH_FEATURE_TYPES_REQUEST = 'FETCH_FEATURE_TYPES_REQUEST';
export const FETCH_FEATURE_TYPES_REQUEST_SUCCESS = 'FETCH_FEATURE_TYPES_REQUEST_SUCCESS';
export const FETCH_FEATURE_TYPES_REQUEST_FAILURE = 'FETCH_FEATURE_TYPES_REQUEST_FAILURE';

export const FETCH_JIRA_PLATFORM_REQUEST = 'FETCH_JIRA_PLATFORM_REQUEST';
export const FETCH_JIRA_PLATFORM_REQUEST_SUCCESS = 'FETCH_JIRA_PLATFORM_REQUEST_SUCCESS';
export const FETCH_JIRA_PLATFORM_REQUEST_FAILURE = 'FETCH_JIRA_PLATFORM_REQUEST_FAILURE';

export const FETCH_JIRA_PRIORITIES_REQUEST = 'FETCH_JIRA_PRIORITIES_REQUEST';
export const FETCH_JIRA_PRIORITIES_REQUEST_SUCCESS = 'FETCH_JIRA_PRIORITIES_REQUEST_SUCCESS';
export const FETCH_JIRA_PRIORITIES_REQUEST_FAILURE = 'FETCH_JIRA_PRIORITIES_REQUEST_FAILURE';

export const FETCH_FEEDBACK_FORM_DATA_REQUEST = 'FETCH_FEEDBACK_FORM_DATA_REQUEST';
export const FETCH_FEEDBACK_FORM_DATA_REQUEST_SUCCESS = 'FETCH_FEEDBACK_FORM_DATA_REQUEST_SUCCESS';
export const FETCH_FEEDBACK_FORM_DATA_REQUEST_FAILURE = 'FETCH_FEEDBACK_FORM_DATA_REQUEST_FAILURE';

export const FETCH_PRODUCT_DOWNLOAD_REQUEST = 'FETCH_PRODUCT_DOWNLOAD_REQUEST';
export const FETCH_PRODUCT_DOWNLOAD_REQUEST_SUCCESS = 'FETCH_PRODUCT_DOWNLOAD_REQUEST_SUCCESS';
export const FETCH_PRODUCT_DOWNLOAD_REQUEST_FAILURE = 'FETCH_PRODUCT_DOWNLOAD_REQUEST_FAILURE';

export const CLEAR_PROGRAM_KEY = 'CLEAR_PROGRAM_KEY';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_AUTH_IS_LOADING = 'SET_AUTH_IS_LOADING';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
