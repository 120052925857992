import * as types from '../constants/actionTypes';

let loadingCounter = 0;

export default function loadingReducer(state, action) {
  switch (action.type) {
    case types.FETCH_AEM_CONTENT:
    case types.FETCH_PROGRAM_DETAIL:
    case types.FETCH_PROGRAM:
      {
        if (!action.isLoadingDisabled) {
          loadingCounter += 1;
        }
      }
      break;

    case types.FETCH_AEM_CONTENT_SUCCESS:
    case types.FETCH_AEM_CONTENT_FAILURE:
    case types.FETCH_PROGRAM_DETAIL_SUCCESS:
    case types.FETCH_PROGRAM_DETAIL_FAILURE:
    case types.FETCH_PROGRAM_SUCCESS:
    case types.FETCH_PROGRAM_FAILURE:
      {
        if (!action.isLoadingDisabled) {
          loadingCounter -= 1;
        }
      }
      break;

    case types.RESET_LOADING:
      loadingCounter = 0;
  }

  return loadingCounter > 0 ? true : false;
}
