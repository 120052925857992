import { stringifyUrl } from 'query-string'
import { API_ROOT } from 'src/utils/services'

export const redirectToLogin = () => {
  const redirectUrl = stringifyUrl({
    url: `${API_ROOT}/session/login`,
    query: {
      RelayState: window.location.href,
    },
  })

  window.location.href = redirectUrl
}
