import { Provider } from 'react-redux'

import { store } from 'src/store/configureStore';
import { ReactHoc } from 'src/types/hocs';

export const withRedux: ReactHoc = (Component) => (props) =>
  (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  )
