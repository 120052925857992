export const GTM_EVENT = Object.freeze({
  cancelClick: 'cancelClick',
  clickTimer: 'clickTimer',
  clickTimerInterval: 'clickTimerInterval',
  feedbackStart: 'feedbackStart',
  feedbackSubmit: 'feedbackSubmit',
  modalClose: 'modalClose',
});

export const TEXT_FEATURE_REQUEST = "Your feedback is vitally important in making each of our products the best that they can be. Our team will be in touch should we require further clarification. We'll continue to keep you posted of all the new features released in our product."
export const TEXT_BUG_REPORT  = "Thank you for taking the time to submit feedback. We will review and a member of the team will follow-up with you."

export enum FeedbackTabKeys {
  FeedbackReport = 1,
  IdeasSubmission = 2,
}