import { FC } from 'react';
import { connect } from 'react-redux';
import LoadingIcon from './LoadingIcon';

import '../../assets/styles/LoadingScreen.scss';

type LoadingScreenPresentationalProps = {
  isLoading: boolean;
};

export const LoadingScreenPresentational: FC<LoadingScreenPresentationalProps> = ({
  isLoading,
}) => (
  <>
    {isLoading && (
      <div className='spinner'>
        <span className='loading-img'>
          <LoadingIcon />
        </span>
      </div>
    )}
  </>
);

LoadingScreenPresentational.defaultProps = {
  isLoading: false,
};

function mapStateToProps(state: LoadingScreenPresentationalProps) {
  return {
    isLoading: state.isLoading,
  };
}

export default connect(mapStateToProps, null)(LoadingScreenPresentational);
