export const ERROR_LITERALS = {
  REQUIRES_EULA_VALIDATION: 'REQUIRES_EULA_VALIDATION',
  NETWORK_ERROR: 'NETWORK_ERROR',
};

export const PROGRAM_TYPES = Object.freeze({
  APPLICATION_SETUP: 'Beta / Preview Application Setup (EPAT use only)',
  CAB: 'CAB Setup (EPAT use only)',
  PROGRAM_SETUP: 'Beta / Preview Program Setup'
});

export const VOC_URL = () => {
  const currentUrl = window.location.href;
  if (currentUrl.search('qa') !== -1 || currentUrl.search('localhost') !== -1) {
    return 'https://voc.qa.splunk.com';
  } else if (currentUrl.search('staging') !== -1) {
    return 'https://voc.staging.splunk.com';
  }
  return 'https://voc.splunk.com';
};

export const SURVEY_POPUP_TIMER_IN_SECONDS = 60;