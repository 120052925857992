import { get } from "lodash";

import { api } from '../api'

const surveyStatusService = async () => {
    const result = await api.get(
    `${process.env.REACT_APP_PRE_RELEASE_API_ENDPOINT || 'https://pre-release.splunk.com/api/v2/pre-release'}/survey/status/ui`, 
    {}
    );
    
    const data = result.data;
    const surveySeason = get(data, "data.survey_active", false);

    return {
        surveySeason: surveySeason,
    };
}

export default surveyStatusService
