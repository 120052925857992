import { FC } from 'react';
import { loading } from './LoadingIcon.style';

export const LoadingIcon: FC = () => (
  <div className={loading} data-testid='loading-icon-testid'>
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29.6 16C30.9255 16 32.0184 17.0805 31.8203 18.3911C31.4704 20.7061 30.6151 22.9262 29.3035 24.8891C27.5454 27.5203 25.0466 29.5711 22.1229 30.7821C19.1993 31.9931 15.9822 32.3099 12.8786 31.6926C9.77486 31.0752 6.92393 29.5513 4.68629 27.3137C2.44865 25.0761 0.924797 22.2251 0.307435 19.1214C-0.309928 16.0177 0.00692639 12.8007 1.21793 9.87706C2.42893 6.95344 4.47969 4.45458 7.11088 2.69648C9.07378 1.38491 11.2939 0.529549 13.609 0.179657C14.9196 -0.0184286 16 1.07452 16 2.4C16 3.72548 14.9135 4.77429 13.6183 5.05616C12.254 5.35308 10.9502 5.90402 9.77762 6.68754C7.93579 7.91821 6.50025 9.66741 5.65255 11.7139C4.80485 13.7605 4.58305 16.0124 5.0152 18.185C5.44736 20.3576 6.51405 22.3532 8.0804 23.9196C9.64675 25.4859 11.6424 26.5526 13.815 26.9848C15.9876 27.417 18.2395 27.1952 20.2861 26.3475C22.3326 25.4998 24.0818 24.0642 25.3125 22.2224C26.096 21.0498 26.6469 19.746 26.9438 18.3817C27.2257 17.0866 28.2745 16 29.6 16Z'
        fill='url(#paint0_radial)'
      />
      <defs>
        <radialGradient
          id='paint0_radial'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(35.75 34.5859) rotate(-139.865) scale(46.7607 32.0476)'
        >
          <stop stopColor='#F89D1C' />
          <stop offset='1' stopColor='#E20082' />
        </radialGradient>
      </defs>
    </svg>
  </div>
);

export default LoadingIcon;
