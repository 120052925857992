import { css, keyframes } from 'emotion'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
export const loading = css`
  width: 32px;
  height: 32px;
  animation: ${spin} 2s linear infinite;
`
