import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import VideoModalVidyard from './VideoModalVidyard';
import { GridContainer } from '../Grid';
import { API_ROOT } from '../../../utils/services';

const STATES = {
  error: 'error',
  loading: 'loading',
  success: 'success',
};

const INITIAL_STATE = {
  videos: [],
  fetchRequestStatus: STATES.loading,
};

const extractOrder = (value) => {
  if (value === null) return null;

  return parseInt(value.split(':').pop());
};

export const ProgramVideoLoader = ({ programId }) => {
  const [videos, setVideos] = useState(INITIAL_STATE.videos);
  const [fetchRequestStatus, setFetchRequestStatus] = useState(INITIAL_STATE.fetchRequestStatus);

  useEffect(() => {
    fetch(`${API_ROOT}/programs/videos?tag=${programId}`)
      .then((res) => res.json())
      .then(({ players }) => {
        setFetchRequestStatus(STATES.success);
        let videos = players.reduce((ac, current) => {
          const props = {
            uuid: current.uuid,
            name: current.chapters_attributes[0].video_attributes.name,
            description: current.chapters_attributes[0].video_attributes.description,
            order: extractOrder(current.chapters_attributes[0].video_attributes.notes),
          };
          ac.push(props);
          return ac;
        }, []);
        videos.sort((a, b) => {
          if (a.order === null) return 1;
          if (b.order === null) return -1;

          return a.order - b.order;
        });
        setVideos(videos);
      })
      .catch((err) => {
        console.error(err);
        setFetchRequestStatus(STATES.error);
      });
  }, []);

  return (
    <div>
      {fetchRequestStatus === STATES.success && (
        <GridContainer>
          {videos.map((v) => (
            <VideoModalVidyard
              key={v.uuid}
              title={v.name}
              description={v.description}
              videoPlayerSettings={{ videoId: v.uuid }}
            />
          ))}
        </GridContainer>
      )}
      {fetchRequestStatus === STATES.loading && (
        <div className='fake-loader-wrapper'>
          <div className='video-fake-loader' />
          <div className='video-fake-loader' />
          <div className='video-fake-loader' />
        </div>
      )}
      {fetchRequestStatus === STATES.error && (
        <div className='container'>
          <span className='row'>Something went wrong</span>
        </div>
      )}
    </div>
  );
};

ProgramVideoLoader.propTypes = {
  programId: PropTypes.string.isRequired,
};
