import React from 'react';
import PropTypes from 'prop-types';
import '../tabs.scss';

export const Tab = ({ icon, label, link }) => {
  return (
    <>
      {icon}
      {label}
      {link}
    </>
  );
};

Tab.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  link: PropTypes.string
};

Tab.defaultProps = {
  icon: null,
  link: null
};
