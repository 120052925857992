/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { pipe } from 'lodash/fp';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import ProgramDetail from '../components/ProgramDetail';
import { fetchProgramDetail } from '../actions/programAction';
import {
  createFeatureRequest as createFeatureRequestAction,
  fetchFeedbackTypes as fetchFeedbackTypesAction,
} from '../actions/feedbackActions';
import {
  fetchJiraPriorities as fetchJiraPrioritiesAction,
  fetchJiraPlatforms as fetchJiraPlatformsAction,
} from '../actions/jiraActions';

class ProductDetailContainer extends React.Component {
  async componentDidMount() {
    const {
      fetchProgramDetail,
      location: { search },
    } = this.props;
    const values = queryString.parse(search);
    const sendFeedback = values.sendFeedback ? 'true' : 'false';
    const {
      match: {
        params: { key },
      },
    } = this.props;
    await fetchProgramDetail(key, sendFeedback);
  }

  render() {
    const {
      createFeatureRequest,
      programsDetail,
      user,
      location: { search },
    } = this.props;
    const values = queryString.parse(search);
    const sendFeedback = values.sendFeedback ? 'true' : 'false';

    return (
      <ProgramDetail
        programsDetail={programsDetail}
        user={user}
        createFeatureRequest={createFeatureRequest}
        sendFeedback={sendFeedback}
      />
    );
  }
}

ProductDetailContainer.propTypes = {
  programsDetail: PropTypes.shape({}).isRequired,
  fetchProgramDetail: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    programsDetail: state.programsDetail,
    user: state.auth.user,
    sendFeedback: state.sendFeedback,
    jiraPlatformVersions: state.jiraPlatformVersions,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchProgramDetail: (key, sendFeedback) => dispatch(fetchProgramDetail(key, sendFeedback)),
  createFeatureRequest: (data) => dispatch(createFeatureRequestAction(data)),
  fetchFeedbackTypes: () => dispatch(fetchFeedbackTypesAction()),
  fetchJiraPriorities: () => dispatch(fetchJiraPrioritiesAction()),
  fetchJiraPlatforms: () => dispatch(fetchJiraPlatformsAction()),
});

export default pipe([withRouter, connect(mapStateToProps, mapDispatchToProps)])(
  ProductDetailContainer
);
