import isEmpty from 'lodash/isEmpty'
import { useCookies } from 'react-cookie';

const usePopupSurvey = () => {
    const [cookies, setCookie] = useCookies(['survey']);

    const setSurveyCookie = (dontShowPopup) => {
        if (dontShowPopup) {
            const cookieExpirationDate = new Date();
            cookieExpirationDate.setMonth(cookieExpirationDate.getMonth() + 3)
            setCookie('survey', false, { path: '/', expires: cookieExpirationDate });

            return;
        }
        setCookie('survey', true, { path: '/' });
    }

    if (isEmpty(cookies?.survey)) {
        return {
            showSurveyCookie: true,
            setSurveyCookie: setSurveyCookie,
        }
    }

    return {
        showSurveyCookie: JSON.parse(cookies?.survey),
        setSurveyCookie: setSurveyCookie,
    }
}

export default usePopupSurvey;
