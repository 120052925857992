import Promise from 'promise-polyfill';
import { redirectToLogin } from 'src/helpers/redirect';

import { getCached, cacheResult } from './localCache';

// Cached Service call Wrapper
export const cacheCall = (api, method, path, name, init_args = {}, cache_ms = 0) => {
  const { init, success } = actionTypes(name);
  return (dispatch) => {
    // Check cache...
    const cache_key = 'api_call_' + method + '_' + path;
    const data = getCached(cache_key);
    if (data) {
      // dispatch init and success events
      dispatch({ type: init, ...init_args, fromCache: true });
      return dispatch({ type: success, data });
    }
    // Not cached - run the call as we normally would
    return wrapCall(
      api[method].call(api, path),
      name,
      'fetch',
      init_args
    )(dispatch).then((resp) => {
      // API call complete, cache the results
      if (cache_ms > 0 && resp && resp.data) {
        cacheResult(cache_key, resp.data, cache_ms);
      }
    });
  };
};

// Service call wrapper
// returns 'thunk' function that wraps service calls with fetch notifications (init, success, failure)
// Handles traditionally caught errors as well as apisauce 'problems'
// 'action_type' is used to generate init, success, and failure action types
export const wrapCall = (promise, action_type, init_args = {}) => {
  return (dispatch) => {
    const { init, success, fail } = actionTypes(action_type);
    dispatch({ type: init, promise, ...init_args });
    return promise
      .then((resp) => {
        if(resp.status === 401 && ['FETCH_PROGRAM', 'FETCH_PROGRAM_DETAIL'].includes(action_type)) {
          redirectToLogin()
        }

        // check for valid response
        if (resp && resp.ok) {
          dispatch({ type: success, data: resp.data, headers: resp.headers, ...init_args });
          return Promise.resolve({ data: resp.data });

          //suppress notification on 404 errors, which are expected from the API
        } else if (resp && resp.status === 404) {
          dispatch({
            type: fail,
            data: resp.data,
            error: resp.problem,
            headers: resp.headers,
            status: resp && resp.status,
            ...init_args,
          });
          return Promise.reject({ data: resp.problem, error: resp.problem });

          //all other errors get reported
        } else {
          // Unified apisauce errors flow through here
          dispatch({
            type: fail,
            data: resp && resp.data,
            error: resp && resp.problem,
            headers: resp && resp.headers,
            status: resp && resp.status,
            ...init_args,
          });
          return Promise.reject({ data: resp && resp.data, error: resp && resp.problem });
        }
      })
      .catch((error) => {
        if (error instanceof Error) {
          // eslint-disable-next-line no-console
          console.error(error);
          // redux and other errors come here
          dispatch({ type: fail, error: error, ...init_args });
        }

        return Promise.reject(error);
      });
  };
};

// async action type template
// ex:
// const { init , success, fail } = actionTypes('THING')
export const actionTypes = (prefix = 'fetch_data') => {
  prefix = prefix.toUpperCase();
  return {
    init: prefix,
    success: prefix + '_SUCCESS',
    fail: prefix + '_FAILURE',
  };
};
