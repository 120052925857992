import UserDownloadActivity from '../types/userDownloadActivity';
import { axiosApi } from './services';

export const logActivity = async (details: UserDownloadActivity) => {
  try {
    await axiosApi.post('/users/activities', details);
  } catch (err) {
    console.error(`error updating user activities - ${err}`);
  }
};
