
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactHoc } from 'src/types/hocs'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retry: false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const withReactQuery: ReactHoc = (Component) => (props) =>
  (
    <QueryClientProvider client={queryClient}>
      <Component {...props} />
    </QueryClientProvider>
  )
