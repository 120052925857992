/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ERROR_LITERALS } from '../constants/config';
import { stringifyUrl } from 'query-string';

export const EulaProtection = (Component) => {
  const EulaWrapper = ({ programsDetail, ...rest }) => {
    const { error, is_program_eula_required: isProgramEulaRequired, status } = programsDetail || {};
    const { code: errorCode, redirectUrl } = error || {};

    if (!isNil(isProgramEulaRequired) && !isProgramEulaRequired) {
      return <Component {...rest} />;
    }

    const statusNotNeedEula = status === 401 || status === 403 || status === 404;
    const requiresEula =
      errorCode === ERROR_LITERALS.REQUIRES_EULA_VALIDATION && redirectUrl && !statusNotNeedEula;
    if (requiresEula) {
      window.location.href = stringifyUrl({
        url: redirectUrl,
        query: {
          returnUrl: window.location.href
        }
      });
    }
    return requiresEula ? (
      <div
        style={{ height: '10rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        Redirecting...
      </div>
    ) : (
      <Component {...rest} />
    );
  };

  EulaWrapper.propTypes = {
    programsDetail: PropTypes.shape({}).isRequired,
  };

  const mapStateToProps = ({ programsDetail }) => ({ programsDetail });

  return connect(mapStateToProps)(EulaWrapper);
};
