import { cloneDeep } from 'lodash';
import * as types from '../constants/actionTypes';

import initialState from './initialState';

export default function programDetailReducer(
  state = cloneDeep(initialState.programsDetail),
  action
) {
  switch (action.type) {
    case types.FETCH_PROGRAM_DETAIL_SUCCESS: {
      // TODO: this is done on success because backend cannot deliver forbidden messages yet, because of CDN limitations
      // once this is fixed we can safely remove this code and just keep the failure handler below.
      const { data } = action || {};
      const { code = '' } = data || {};
      return code === 'REQ_SUCCESS' ? data.data : { ...state, error: action.data };
    }

    case types.FETCH_PROGRAM_DETAIL_FAILURE: {
      const { status = 0, data: { additionalInfo = null } = {} } = action;
      return {
        ...state,
        error: additionalInfo,
        status,
      };
    }
    case types.CLEAR_PROGRAM_KEY: {
      return {
        ...cloneDeep(initialState.programsDetail),
      };
    }

    default:
      return state;
  }
}
