import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ProgramList from '../components/ProgramList';
import {fetchProgramList as fetchProgramListAction, clearProgramKey as clearProgramKeyAction} from '../actions/programAction';

const ProductContainer = ({fetchProgramList, clearProgramKey, programs}) => {
  useEffect(() => {
    fetchProgramList('endDate', 'ASC');
  }, []);

  return (
    <ProgramList programs={programs} clearProgramKey={clearProgramKey} onSortPrograms={(value) => fetchProgramList(value, 'ASC')}>
      {' '}
    </ProgramList>
  );
};

ProductContainer.propTypes = {
  programs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchProgramList: PropTypes.func.isRequired,
  clearProgramKey: PropTypes.func.isRequired,
};

const mapStateToProps = ({programs}) => ({programs});

const mapDispatchToProps = (dispatch) => ({
  fetchProgramList: (sortBy, sortDirection) => dispatch(fetchProgramListAction(sortBy, sortDirection)),
  clearProgramKey: () => dispatch(clearProgramKeyAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
