/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unsafe */

import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DocumentationFooter from './DocumentationFooter';
import DownloadFooter from './DownloadFooter';
import FeedbackModalContainer from '../containers/FeedbackModalContainer';
import { ProgramVideoLoader } from './common/VidyardVideo/ProgramVideoLoader';
import { ImageModal } from './common/ImageModal';
import { ErrorMessage } from './common/ErrorMessage/ErrorMessage';
import { PROGRAM_TYPES, VOC_URL } from '../constants/config';

import '../assets/styles/style.scss';
import '../assets/styles/previewprogram.scss';
import OtherAssets from './OtherAssets';

const vocUrl = VOC_URL();

export function parseDescription(desc, user) {
  const TOKENS = {
    USER_FIRST_NAME: /%USER_FIRST_NAME%/gim,
    USER_LAST_NAME: /%USER_LAST_NAME%/gim,
    USER_EMAIL: /%USER_EMAIL%/gim,
    USERNAME: /%USERNAME%/gim,
  };

  if (desc) {
    return desc
      .replace(TOKENS.USER_EMAIL, user.email)
      .replace(TOKENS.USER_FIRST_NAME, user.firstName)
      .replace(TOKENS.USER_LAST_NAME, user.lastName)
      .replace(TOKENS.USERNAME, user.username);
  }

  return desc;
}

export default class ProgramDetail extends Component {
  state = {
    show: false,
    ideasPortalReady: false,
    height: '0px'
  };
  
  componentWillReceiveProps() {
    const { sendFeedback } = this.props;
    if (sendFeedback === 'true') {
      this.showModal();
    } else {
      return true;
    }
  }

  showModal = () => {
    this.setState({ show: true });
  };

  openIdeasPopup = () => {
    const { programsDetail } = this.props;
    if (programsDetail && programsDetail.ideas_portal_url) {
      this.hideIdeasPortalLoadingIndicatorCounter = 0;
      this.setState({
        ideasPortalReady: false,
      });
      window.addEventListener('message', this.handlePopupOpened, false);
      this.popupWindow = window.open(
        programsDetail.ideas_portal_url,
        'ideasPopup',
        'width=300,height=300'
      );
    }
  };

  handlePopupOpened = () => {
    if (event.data && event.data.message && event.data.message === 'PORTAL_READY') {
      window.removeEventListener('message', this.handlePopupOpened, false);
      if (this.popupWindow) {
        this.popupWindow.close();
      }
      this.setState({ ideasPortalReady: true });
    }
  };

  closeModal = () => this.setState({ show: false });

  frameWrapper = (survey_url) => {
    const { height } = this.state;
    const onLoad = () => {
      this.setState({ height: document.body.scrollHeight + "px" });
    };
    return (
      <iframe
        title='survey-iframe'
        data-testid='survey_iframe_testid'
        onLoad={onLoad}
        id='surveyIframe'
        src={survey_url}
        height={height}
        style={{
          width: "100%",
          overflow: "none",
          maxHeight: "1200px"
        }}
      />
    );
  }

  formatEndDate(date) {
    var dDateOnly = '';
    var monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    if (date) {
      var splitDate = JSON.stringify(date).includes('T') ? date.split('T')[0] : date;
      var enddate = new Date(splitDate);
      var monthIndex = enddate.getMonth();
      dDateOnly = `${enddate.getDate()} ${monthNames[monthIndex]} ${enddate.getFullYear()}`;
    }
    return dDateOnly;
  }

  renderProgramStatus = () => {
    const { programsDetail, user } = this.props;
    const { content, program_key, description, builds_id, survey_url } = programsDetail;
    const { images } = content;

    const release = {
      id: programsDetail.release_id,
      version: programsDetail.release_version,
      product: programsDetail.product_name,
    };

    const otherAssetsEventKey = builds_id ? 2 : 1;
    const buildsId = builds_id?.split(',');

    switch (programsDetail.status) {
      case 404:
        return (
          <ErrorMessage
            displayIndication='Please reach out to your Program contact for further information. Thank you.'
            displayMessage='The program you are trying to access has already expired.'
          />
        );
      case 403:
      case 401:
        return (
          <ErrorMessage
            displayIndication='Please get in touch with your Program contact for next steps. Thank you.'
            displayMessage='You currently do not have access to the program.'
          />
        );
      default:
        return new Date(programsDetail.start_date) > new Date() ? (
          <ErrorMessage
            displayIndication='Please reach out to your Program contact for further information. Thank you.'
            displayMessage={`The program you're trying to access has not started yet.`}
          />
        ) : (
          <div className='container program-detail' data-testid='program-detail'>
            <div className='row'>
              <div className='col-sm-12 col-md-9'>
                <div className='splunk-body'>
                  <p dangerouslySetInnerHTML={{ __html: parseDescription(description, user) }} />
                  {survey_url && <div className='row'>{this.frameWrapper(survey_url)}</div>}
                </div>
                {program_key && <ProgramVideoLoader programId={program_key} />}
              </div>
            </div>
            <div className='row'>
              {!!images.length &&
                images.map((val) => (
                  <div className='col-md-4' key={val}>
                    <div className='media-cnt'>
                      <ImageModal url={val} alt='' />
                    </div>
                  </div>
                ))}
            </div>
            <Tabs defaultActiveKey={1} id='tabs-program-detail' data-testid='tabs-program-detail'>
              {builds_id && (
                <Tab
                  eventKey={1}
                  data-testid='tabs-program-detail-builds-tab'
                  title='Product Build'
                  tabClassName='feature-request'
                >
                  {release?.id && <DownloadFooter buildsId={buildsId} release={release} />}
                </Tab>
              )}
              {content?.other_assets &&
                content.other_assets?.length > 0 &&
                Object.hasOwn(content.other_assets[0], 'filename') && (
                  <Tab
                    eventKey={otherAssetsEventKey}
                    data-testid='tabs-program-detail-other-assets-tab'
                    title='Other Assets'
                    tabClassName='feature-request'
                  >
                    <OtherAssets assets={content.other_assets} />
                  </Tab>
                )}
            </Tabs>
            <DocumentationFooter
              document_link={content.document_link}
              release_notes_url={content.release_notes_url}
              system_requirement={content.system_requirement}
            />
          </div>
        );
    }
  };

  render() {
    const { programsDetail, createFeatureRequest } = this.props;
    const { show, ideasPortalReady } = this.state;
    const { end_date, display_name, status } = programsDetail;
    const disabledSendFeedback =
      status === 401 ||
      status === 403 ||
      status === 404 ||
      new Date(programsDetail.start_date) > new Date();

    const programIsNotApplicationPreview = programsDetail.program_type !== PROGRAM_TYPES.CAB &&
      programsDetail.program_type !== PROGRAM_TYPES.APPLICATION_SETUP

    let breadcrumbReturnURL = programIsNotApplicationPreview ? '/preview' : vocUrl;

    return (
      <div>
        <div id='content'>
          <div className='pre-release-content'>
            <section className='express-lane-hero splunk-background express-lane-tabbing-component inverted'>
              <div className='overlay-image bottom-background' />
              <div className='container'>
                <div className='row useful-tools-row'>
                  <div className='col-md-8 col-splunk-8 expresslane-hero-padding inverted'>
                    <h6 className='splunk2-eyebrow'>GET STARTED</h6>
                    <h2 id='downloading-val' className='splunk2-h2 h2-max-width'>
                      {display_name}
                    </h2>
                    <p className='splunk2-lead-md splunk-color splunk-white'>
                      Available through {this.formatEndDate(end_date)}
                    </p>
                  </div>
                  <div className='col-md-4 col-splunk-4 text-right hero-voc-image-detail'>
                    <img src='/voc-banner.svg' alt='Voice of the customer banner' />
                  </div>
                </div>
              </div>
            </section>
            <div data-testid='breadcrumb' className='breadcrumb'>
              <div className='container'>
                <div className='item body-small'>
                  {!programIsNotApplicationPreview && <a data-testid='breadcrumb-link' href={breadcrumbReturnURL}>Splunk VOC Programs </a>}
                  {programIsNotApplicationPreview && <Link data-testid='breadcrumb-link' to={breadcrumbReturnURL}>Splunk VOC Programs </Link>}
                  <span className='glyphicon glyphicon-menu-right' />{' '}
                  <strong>{display_name}</strong>
                </div>
              </div>
            </div>
            {
              programIsNotApplicationPreview &&
              <section id='send-feedback-section'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-8 col-splunk-8' />
                    <div className='col-md-4 col-splunk-4 text-right'>
                      <a
                        id='button-link-target'
                        href='#'
                        className='splunk-btn sp-btn-white-hollow'
                        data-toggle='modal'
                        data-target='#sendFeedbackModal'
                        onClick={disabledSendFeedback ? () => null : this.showModal}
                        disabled={disabledSendFeedback}
                        data-testid='send-feedback-button'
                      >
                        Send Feedback
                      </a>
                    </div>
                  </div>
                </div>
              </section>}
            <div className='splunk-padding'>{this.renderProgramStatus()}</div>
          </div>
        </div>
        <FeedbackModalContainer
          onClose={this.closeModal}
          show={show}
          createFeatureRequest={createFeatureRequest}
          programsDetail={programsDetail}
          ideasPortalReady={ideasPortalReady}
          openIdeasPopup={this.openIdeasPopup}
        />
      </div>
    );
  }
}

ProgramDetail.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  sendFeedback: PropTypes.string,
  programsDetail: PropTypes.object,
  createFeatureRequest: PropTypes.func,
};
