import React, { useState, useEffect } from 'react';

import { Attachment } from '../../types/feedback';

interface UploadAttachmentProps {
  programKey: string;
  setAttachment: (type: string, attachments: Attachment[]) => void;
  type: string;
}

const buildServiceUrl = (url: string) => {
  if (url.includes('staging')) return 'https://uploadsvc.staging.splunk.com';

  if (url.includes('webqa') || url.includes('webqa1')) return 'https://uploadsvc.webqa.splunk.com';

  return 'https://uploadsvc.splunk.com';
};

// needed to prevent event bubbling and allow UI to load attachments outside the upload area provided
const preventDragNDropEvents = (e: DragEvent) => e.preventDefault();

const UploadAttachment = ({ programKey, setAttachment, type }: UploadAttachmentProps) => {
  const serviceUrl = buildServiceUrl(window.location.host);
  const iframeUrl = `${serviceUrl}/upload/pre-release/null?is_external=true&program_key=${programKey}&hide_aem_header=true#/`;
  const popupUrl = `${serviceUrl}/upload/iframepopup`;

  const [showWidget, setShowWidget] = useState(false);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== serviceUrl) {
        return;
      }

      setShowWidget(true);
    };

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const toggleUploadAttachmentsVisibility = () => {
    if (showWidget) {
      setShowWidget(false);
    } else {
      window.open(popupUrl, 'uploadAttachmentsPopup', 'width=300,height=600');
    }
  };

  useEffect(() => {
    window.addEventListener('dragover', preventDragNDropEvents, false);
    window.addEventListener('drop', preventDragNDropEvents, false);

    return () => {
      window.removeEventListener('dragover', preventDragNDropEvents, false);
      window.removeEventListener('drop', preventDragNDropEvents, false);
    };
  }, []);

  const renameAttachmentParams = (attachments: { location: string; description: string }[]) =>
    attachments &&
    attachments.map((e) => ({ filePath: e.location, fileDescription: e.description }));

  const readAttachments = (e: MessageEvent) => {
    const data = e.data;

    if (data && !!data.length) {
      const attachments = renameAttachmentParams(data);
      setAttachment(type, attachments);
    }
  };

  const onLoadIframe = () => window.addEventListener('message', readAttachments, false);

  return (
    <div className='upload-wrapper'>
      <button
        type='button'
        className='btn sp-btn-pink btn-upload'
        onClick={toggleUploadAttachmentsVisibility}
      >
        <b>Upload attachments (i.e. .png, .diag)</b>
      </button>
      {showWidget && (
        <div className='widget-wrapper' data-testid='widget-wrapper'>
          <span className='label-text upload-title'>Attachments</span>
          <span className='widget-wrapper mandatory-field'>
            <span>Please Note:</span>
            <span>1) Please click on Upload before submitting feedback to attach file</span>
            <span>2) File Comment cannot be edited once attachment is uploaded</span>
          </span>
          <iframe
            onLoad={onLoadIframe}
            data-test='upload-frame'
            className='upload-frame'
            title='upload-component'
            src={iframeUrl}
          />
        </div>
      )}
    </div>
  );
};

export default UploadAttachment;
