import PropTypes from 'prop-types';
import React from 'react';

export const PackageLinkTable = ({ packages_links, trackDownload }) => (
  <div className='col-xs-9 col-xs-offset-3 col-sm-6 col-sm-offset-0 col-md-6 col-lg-6 table-wrapper'>
    <table className='versions-table'>
      <tbody>
        {packages_links.map((plink, index) => {
          return (
            <tr id={index} key={plink.filename}>
              <td>
                <span className='filename strong'>{plink.filename}</span>
              </td>
              <td style={{ textAlign: 'right' }}>
                <a
                  href={plink.links.downloadLink}
                  className='splunk-btn sp-btn-pink'
                  style={{ margin: '5px' }}
                  download
                  onClick={() => trackDownload(plink.filename)}
                >
                  <span className='glyphicon glyphicon-save' />
                  Download Now
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);

PackageLinkTable.propTypes = {
  packages_links: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      filename: PropTypes.string,
      legacy_download_link: PropTypes.string,
      md5: PropTypes.string,
      sha512: PropTypes.string,
    })
  ).isRequired,
  trackDownload: PropTypes.func.isRequired,
};
