import axios from 'axios'

const handleUnauthenticatedError = (errorCode: number) => {
  if (errorCode === 401) {
    window.location.replace('/logout')
  }
}

const api = axios.create()

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    handleUnauthenticatedError(error?.response?.status)
    throw error?.response
  },
)

export { api, handleUnauthenticatedError }
