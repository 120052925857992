import React, {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Modal } from 'react-bootstrap';
import { find, cloneDeep } from 'lodash';

import validator from '../../utils/validation';
import constants from '../../constants';
import FeedbackInitialState from '../../reducers/feedbackInitialState';
import FeedbackReportTab from './FeedbackReportTab';
import {
  FeedbackTabKeys,
  GTM_EVENT,
  TEXT_BUG_REPORT,
  TEXT_FEATURE_REQUEST,
} from '../../constants/feedback';
import {
  Attachment,
  FeedbackCssClass,
  FeedbackFormData,
  FeedbackFormErrors,
  FeedbackIsValid,
  FeedbackState,
  GTMEventValues,
} from '../../types/feedback';
import { ProgramDetail } from '../../types/program';

import '../../assets/styles/feedbackModal.scss';

export interface FeedbackModalProps {
  feedbackFormData: FeedbackFormData;
  fetchFeedbackFormData: () => any;
  createFeatureRequest: (data: any) => void;
  onClose: (event: MouseEvent<HTMLButtonElement>) => void;
  show: boolean;
  programsDetail: ProgramDetail;
  ideasPortalReady: boolean;
}

const TextFeatureRequest = () => <p>{TEXT_FEATURE_REQUEST}</p>;

const TextFeedbackReport = () => <p>{TEXT_BUG_REPORT}</p>;

const buildDescription = (description: string, files: Attachment[] = []) => {
  const fileString = files.reduce(
    (ac, current, index) =>
      ac.concat(
        `\n\n file url: [attached file ${index + 1}](${current.filePath}) \n\n description: ${
          current.fileDescription
        } \n\n`
      ),
    ''
  );
  return description.concat(fileString);
};

const FeedbackModal = ({
  feedbackFormData,
  fetchFeedbackFormData,
  createFeatureRequest,
  onClose,
  show,
  programsDetail,
}: FeedbackModalProps) => {
  const [state, setState] = useState<FeedbackState>(cloneDeep(FeedbackInitialState));
  const modalWindow = useRef(null);

  const {
    showFeatureThankyouFeedback,
    showBugThankyouFeedback,
    visible,
    cssClass,
    formErrors,
    bugTitle,
    isBugReportFormValid,
    bugDescribeFeature,
    modalDialogClassName,
  } = state;

  const { ideas_portal_url } = programsDetail;

  useEffect(() => {
    async function getFeedbackFormData() {
      setState((prev) => ({ ...prev, visible: true }));
      await fetchFeedbackFormData();
    }
    getFeedbackFormData();
  }, []);

  const handleBugSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { bugDescribeFeature, bugAttachments, bugTitle, selectedFeedbackType } = state;
    const feedbackType = find(feedbackFormData.feedbackTypes, { name: 'bug' });

    let data = {
      program_id: programsDetail.id,
      feedback_type_id: feedbackType?.id,
      content: {
        title: bugTitle,
        description: buildDescription(bugDescribeFeature, bugAttachments),
        selectedFeedbackType,
      },
    };
    createFeatureRequest(data);
    setState((prev) => ({ ...prev, showBugThankyouFeedback: true }));
    _trackSubmit();
  };

  const resetForm = () => {
    setState(cloneDeep(FeedbackInitialState));
  };

  const handleUserInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { program_key } = programsDetail;
    const fieldName = event.target.name;
    const value = event.target.value;
    validateField(fieldName, value);
    setState((prev) => ({ ...prev, [fieldName]: value }));
    if (fieldName === 'platform' && !constants.programsWithoutBrowser.includes(program_key)) {
      bindPlatformVersion(value);
    }
  };

  const handleFeedbackTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setState((prev) => ({ ...prev, selectedFeedbackType: event.target.value }));
  };

  const handleFieldFocus = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { cssClass } = state;
    const fieldName = event.target.name as keyof FeedbackCssClass;
    let fieldCssClass = cssClass;
    fieldCssClass[`${fieldName}`] = 'focus';
    setState((prev) => ({ ...prev, cssClass: fieldCssClass }));
    validateForm();
  };

  const bindPlatformVersion = (platformValue: string) => {
    const { formErrors, cssClass } = state;
    let versionObj = feedbackFormData.platforms.filter(
      (version: any) => version.internalName === platformValue
    );
    let fieldValidationErrors = formErrors;
    let fieldCssClass = cssClass;
    fieldValidationErrors.platformVersion = '';
    fieldCssClass.platformVersion = '';
    setState((prev) => ({
      ...prev,
      platformVersions: versionObj[0].versions as string[],
      formErrors: fieldValidationErrors,
      cssClass: fieldCssClass,
    }));
    validateForm();
  };

  const validateField = (fieldName: string, value: string) => {
    const { formErrors, cssClass, isValid } = state;
    let fieldValidationErrors = formErrors;
    let fieldCssClass = cssClass;
    let newIsInvalid = isValid;
    let resultObj = validator.validate(fieldName, value);
    fieldValidationErrors[`${fieldName as keyof FeedbackFormErrors}`] = resultObj.formFieldError;
    fieldCssClass[`${fieldName as keyof FeedbackCssClass}`] = resultObj.cssClass;
    newIsInvalid[`${fieldName as keyof FeedbackIsValid}`] = resultObj.isValid;
    setState((prev) => ({
      ...prev,
      formErrors: fieldValidationErrors,
      cssClass: fieldCssClass,
      isValid: newIsInvalid,
    }));
    validateForm();
  };

  const validateForm = () => {
    const { activeTab, isValid } = state;
    if (activeTab.name === 'feature') {
      setState((prev) => ({
        ...prev,
        isFeatureReportFormValid:
          isValid.title && isValid.importanceFB && isValid.describeFeature ? true : false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        isBugReportFormValid: isValid.bugTitle && isValid.bugDescribeFeature,
      }));
    }
  };

  const getOnCloseHandler =
    (gtmEvent: GTMEventValues) => (event: MouseEvent<HTMLButtonElement>) => {
      const { activeTab, showFeatureThankyouFeedback, showBugThankyouFeedback } = state;
      if (gtmEvent && !showFeatureThankyouFeedback && !showBugThankyouFeedback) {
        track(activeTab, gtmEvent);
        track(activeTab, GTM_EVENT.clickTimerInterval);
      }

      // Reset state
      resetForm();
      onClose && onClose(event);
    };

  const handleAttachmentUpload = (type: string, content: any) => {
    setState((prev) => ({ ...prev, [`${type}Attachments`]: content }));
  };

  // Push GTM events to the window data layer to be triggered in GTM to capture for GA
  const track = (
    tab: { name: string; key: number | null; openedAt: number },
    event: GTMEventValues = GTM_EVENT.clickTimer
  ) => {
    window.dataLayer = window.dataLayer || [];
    const keyword = tab.name === 'feature' ? 'ideasSubmission' : 'feedbackReport';

    if (event === GTM_EVENT.clickTimer) {
      window.dataLayer.push({ event, keyword, timeStamp: tab.openedAt });
      window.dataLayer.push({ event: GTM_EVENT.feedbackStart, keyword, timeStamp: tab.openedAt });
    } else {
      const timeStamp = +new Date();
      const timeSpent = (timeStamp - tab.openedAt) / 1000; // spent time in seconds
      window.dataLayer.push({ event, keyword, timeStamp, timeSpent });
    }
  };

  const _trackSubmit = () => {
    const { activeTab } = state;
    track(activeTab, GTM_EVENT.feedbackSubmit);
    track(activeTab, GTM_EVENT.clickTimerInterval);
  };

  const buildActiveTab = (key: number) => {
    const openedAt = +new Date();
    const name = key === FeedbackTabKeys.IdeasSubmission ? 'feature' : 'bug';

    return { key, name, openedAt };
  };

  const renderFeedbackReportTab = () => {
    return (
      <FeedbackReportTab
        handleBugSubmit={handleBugSubmit}
        cssClass={cssClass}
        bugTitle={bugTitle}
        formErrors={formErrors}
        handleFieldFocus={handleFieldFocus}
        handleUserInput={handleUserInput}
        bugDescribeFeature={bugDescribeFeature}
        programsDetail={programsDetail}
        onClose={getOnCloseHandler(GTM_EVENT.cancelClick)}
        handleAttachmentUpload={handleAttachmentUpload}
        isBugReportFormValid={isBugReportFormValid}
        handleFeedbackTypeChange={handleFeedbackTypeChange}
      />
    );
  };

  if (!show) {
    return null;
  }

  return (
    <div>
      <Modal
        id='sendFeedbackModal'
        show={show}
        backdrop='static'
        enforceFocus={false}
        onHide={onClose}
        dialogClassName={
          modalDialogClassName ? modalDialogClassName : ideas_portal_url && 'aha-feedback'
        }
        ref={modalWindow}
        onEntering={() => {
          const activeTab = buildActiveTab(FeedbackTabKeys.FeedbackReport);
          setState((prev) => ({ ...prev, activeTab }));
          track(activeTab);
        }}
      >
        <Modal.Header>
          <h4 className='splunk2-h4 modal-title'>Send Feedback</h4>
          <div className='close-wrap'>
            <button
              type='button'
              onClick={getOnCloseHandler(GTM_EVENT.modalClose)}
              className='close'
            >
              <span aria-hidden='true'>×</span>
              <span className='sr-only'>Close</span>
            </button>
          </div>
        </Modal.Header>
        <div className='nav-tab-container'>
          <h4 className='splunk2-h4'>
            {showFeatureThankyouFeedback || showBugThankyouFeedback ? 'Thank You!' : 'Almost Done!'}
          </h4>
          <div className='splunk-body'>
            {!visible ? <TextFeatureRequest /> : <TextFeedbackReport />}
          </div>
          <Modal.Body
            className={showFeatureThankyouFeedback || showBugThankyouFeedback ? 'display-none' : ''}
          >
            <div className='feedback-content'>{renderFeedbackReportTab()}</div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default FeedbackModal;
