import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ program_key, displayName }) => (
  <div className='breadcrumb'>
    <div className='container'>
      <div className='item body-small'>
        <Link to='/preview/'>Splunk VOC Programs </Link>
        <span className='glyphicon glyphicon-menu-right' />
        <Link to={`/preview/${program_key}`}> {displayName} </Link>
        <span className='glyphicon glyphicon-menu-right' /> <strong>Downloading</strong>
      </div>
    </div>
  </div>
);

Breadcrumbs.propTypes = {
  program_key: PropTypes.string,
  displayName: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  program_key: '',
  displayName: '',
};

export default Breadcrumbs;
