import * as types from '../constants/actionTypes';
import { REQUEST, sendRequest } from '../utils/networkUtils';

export const fetchAEMContent = (locale) => {
  return sendRequest(
    types.FETCH_AEM_CONTENT,
    REQUEST.GET,
    `/aem/contents/${locale && `?locale=${locale}`}`
  );
};
