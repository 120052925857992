/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

const ThankyouHeader = ({ product, version, platform, productLink, md5Link, wgetLink }) => {
  const [message, setMessage] = useState('');

  const copyToClipboard = () => {
    copy(wgetLink);
    setMessage('Copied to clipboard!');
  };

  useEffect(() => {
    setTimeout(() => setMessage(''), 3000);
  });

  return (
    <section className='express-lane-hero splunk-background express-lane-tabbing-component inverted'>
      <div className='overlay-image bottom-background' />
      <div className='container'>
        <div className='row useful-tools-row'>
          <div className='col-md-8 col-splunk-8 expresslane-hero-padding inverted'>
            <div className='splunk2-eyebrow'>GET STARTED</div>
            <h2 className='splunk2-h2 bottom-spacing'>
              {`You are Downloading ${product} ${version} ${platform && `for ${platform}`}`}
            </h2>
            <p>
              Your download should have started. No?
              <a className='thank-you-link' download href={productLink}>
                Try this URL.
              </a>
            </p>
            <p>
              Choose additional platforms
              <a
                target='_blank'
                rel='noopener noreferrer'
                className='thank-you-link'
                href='https://www.splunk.com/en_us/download/splunk-enterprise.html'
              >
                here
              </a>
              .
            </p>
          </div>
          <div className='col-md-4 col-splunk-4 useful-tools-section useful-tools-box'>
            <h6 className='useful-tools-title'>Useful Tools</h6>
            <p>
              Download via
              <a href='#' onClick={copyToClipboard}>
                Command Line (wget)
              </a>
            </p>
            <p>
              Download
              <a download href={md5Link}>
                MD5 to verify
              </a>
              your bits
            </p>
            <p>
              Get data into Splunk with our
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.splunk.com/en_us/download/universal-forwarder.html'
              >
                Universal Forwarder
              </a>
              <br />
            </p>
            <div>{message}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

ThankyouHeader.propTypes = {
  product: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  productLink: PropTypes.string.isRequired,
  md5Link: PropTypes.string.isRequired,
  wgetLink: PropTypes.string.isRequired,
};

ThankyouHeader.defaultProps = {};

export default React.memo(ThankyouHeader);
