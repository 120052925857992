import * as types from '../constants/actionTypes';
import { cloneDeep } from 'lodash';

import initialState from './initialState';

export default function programReducer(state = cloneDeep(initialState.programs), action) {
  switch (action.type) {
    case types.FETCH_PROGRAM_SUCCESS:
      return action.data.data;

    case types.FETCH_PROGRAM_FAILURE:
      return state;

    default:
      return state;
  }
}
