import { connect } from 'react-redux';

import { FeedbackFormData } from '../types/feedback';
import { fetchFeedbackFormData } from '../actions/feedbackActions';
import FeedbackModal from '../components/Feedback/FeedbackModal';

interface StateProps {
  app: {
    currentPage: number;
    lastPage: number;
    locale: string;
    showHeaderPopupForUser: boolean;
  };
  feedbackFormData: FeedbackFormData;
}

const mapStateToProps = (state: StateProps) => {
  return {
    app: state.app,
    feedbackFormData: state.feedbackFormData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchFeedbackFormData: () => dispatch(fetchFeedbackFormData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
