import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const ImageModal = ({ url, alt }) => {
  const [open, setOpen] = React.useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  return (
    <div>
      <div onClick={openModal} onKeyPress={openModal} tabIndex={0} role='button'>
        <figure>
          <img src={url} alt={alt} />
        </figure>
      </div>
      <Modal
        bsSize='large'
        show={open}
        onHide={closeModal}
        aria-labelledby='image-preview-modal'
        aria-hidden='true'
        dialogClassName='image-preview-modal'
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <img style={{ height: '100%', width: '100%' }} src={url} alt={alt} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

ImageModal.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ImageModal.defaultProps = {
  alt: 'pre release program',
};
