// eslint-disable-next-line import/no-mutable-exports
let browserStorage;

if (window.browserStorage) {
  browserStorage = window.browserStorage;
} else if (window.localStorage || typeof window.localStorage.getItem === 'function') {
  browserStorage = window.localStorage;
}

export default browserStorage;
