import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import VidyardEmbed from '@vidyard/embed-code';
import constants from '../../../constants';

export const VideoPlayerVidyardProps = {
  videoId: PropTypes.string.isRequired,
  style: PropTypes.object,
  dataType: PropTypes.string,
  dataVersion: PropTypes.string,
  className: PropTypes.string,
};

export const VideoPlayerVidyardDefaultProps = {
  style: { width: '100%', margin: 'auto', display: 'block' },
  dataType: 'inline',
  dataVersion: '4',
  className: '',
};

const VideoPlayerVidyard = ({ videoId, className, style, dataType, dataVersion }) => {
  useEffect(() => {
    const placeholder = document.querySelector(`[data-uuid='${videoId}']`);
    VidyardEmbed.api.renderPlayer(placeholder);
    return () => {
      const player = VidyardEmbed.api.getPlayersByUUID(videoId)[0];
      VidyardEmbed.api.destroyPlayer(player);
    };
  }, []);
  return (
    <img
      id={videoId}
      style={style}
      className={`vidyard-player-embed invisible ${className}`}
      src={`${constants.vidyard.url}/${videoId}.jpg`}
      data-uuid={videoId}
      data-v={dataVersion}
      data-type={dataType}
    />
  );
};

VideoPlayerVidyard.propTypes = VideoPlayerVidyardProps;
VideoPlayerVidyard.defaultProps = VideoPlayerVidyardDefaultProps;

export default VideoPlayerVidyard;
