import { useState, useEffect } from 'react';
import { API_ROOT } from './services';
import { get } from 'lodash'
import { store } from '../store/configureStore'

const useFetch = (path, method = 'GET', payload) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = get(store.getState(), 'auth.token')

      const headers = {}

      if(token) {
        headers.Authorization =  `Bearer ${token}`
      }

      await fetch(`${API_ROOT}${path}`, {
        headers,
        method,
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          setData(data);
          setLoading(false);
        })
        .catch((e) => {
          if (e.status === 401) localStorage.removeItem('pre_release_ui_token');
          setErrors(e);
        });
    };
    fetchData();
  }, []);

  return { loading, errors, data };
};

export default useFetch;
