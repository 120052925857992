import PropTypes from 'prop-types';
import React from 'react';
import { capitalizeFirstLetter } from './helper';
import { map, get, isEmpty } from 'lodash'

export const AccordionHeader = ({ groupedBuilds, activeTab, onChange }) => {
  if (isEmpty(groupedBuilds)) {
    return null
  }

  const handleSelect = (event, index) => {
    event.preventDefault();
    onChange(index);
  }

  return <>
    <ul id='tabAccordion' className='nav nav-tabs' role='tablist'>
      {map(groupedBuilds, (buildGroup, key) => {
        const platform = get(buildGroup[0], 'productPlatformArchitecture.data.productPlatform.data.platform.data')

        return (
          <li
            role='presentation'
            key={`header-${key}`}
            className={key === activeTab ? 'active' : undefined}
          >
            <a
              href={`#${key}`}
              aria-controls={platform.name}
              role='tab'
              data-toggle='tab'
              onClick={(event) => handleSelect(event, key)}
            >
              <span className={`download-logo ${platform.name.toLowerCase()}`} />
              {capitalizeFirstLetter(platform.name)}
              <span className='glyphicon glyphicon-chevron-down' />
              <span className='glyphicon glyphicon-chevron-up' />
            </a>
          </li>
        );
      })}
    </ul>
  </>
};

const ProductType = PropTypes.objectOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      arch_order: PropTypes.string,
      architecture: PropTypes.string,
      group_name: PropTypes.string,
      platform: PropTypes.string,
      platform_details: PropTypes.string,
      pub_date: PropTypes.string,
      release_notes_link: PropTypes.string,
      release_type: PropTypes.string,
      version: PropTypes.string,
      packages_links: PropTypes.arrayOf(
        PropTypes.shape({
          file: PropTypes.string,
          filename: PropTypes.string,
          legacy_download_link: PropTypes.string,
          md5: PropTypes.string,
          sha512: PropTypes.string,
        })
      ),
    })
  )
);

AccordionHeader.propTypes = {
  groupedBuilds: ProductType.isRequired,
};
