import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs } from './common/Tabs/Tabs';
import { Tab } from './common/Tabs/Tab/Tab';

import '../assets/styles/style.scss';
import '../assets/styles/previewprogram.scss';
import { PROGRAM_TYPES, VOC_URL } from '../constants/config';

export default class ProgramList extends Component {
  constructor(...args) {
    super(...args);
    this.vocUrl = VOC_URL();
  }

  handleFilter = (e) => {
    const { onSortPrograms } = this.props;
    const value = e.target.value;
    onSortPrograms(value);
  };

  formattedDate(date) {
    date = new Date(date);
    var monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  compareDate(date) {
    var today = new Date();
    var enddate = new Date(date);
    var todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    var dDateOnly = new Date(enddate.getFullYear(), enddate.getMonth(), enddate.getDate());

    if (dDateOnly < todayDateOnly) return true;
    return false;
  }
  truncate(str) {
    return str.length > 100 ? str.substring(0, 100) + '...' : str;
  }

  renderPrograms(programList, emptyMessage, isPrivate) {
    const programs = programList.filter(
      program => program.program_type === PROGRAM_TYPES.PROGRAM_SETUP
    );

    const { clearProgramKey } = this.props;
    const styleMargin = isPrivate ? undefined : { marginLeft: 15 };


    if (programs.length === 0) {
      return <div className='empty-program'>{emptyMessage}</div>;
    }

    return (
      <>
        <div className='product-list' style={styleMargin}>
          {programs.map((value, index) => {
            if (value.is_active === 1) {
              return (
                <div className={`row program-row-${(index + 1) % 2 === 0 ? 'even' : 'odd'}`} key={value.id} data-testid='active-programs'>
                  <div className='col-md-4 col-sm-12 col-xs-12'>
                    <Link
                      onClick={() => clearProgramKey()}
                      to={'/preview/' + value.program_key}
                      className='splunk-color program-list-cta'
                      data-testid='active-program'
                    >
                      {value.display_name}
                    </Link>
                  </div>
                  <div
                    className='col-md-5 col-sm-12 col-xs-12 description-text program-short-desc'
                    dangerouslySetInnerHTML={{ __html: this.truncate((value.description).replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "")) }}
                  />
                  <div className='col-md-3 col-sm-12 col-xs-12'>
                    <div className='date-column'>
                      <ul>
                        <li>{this.formattedDate(value.start_date)}</li>
                        <li>{this.formattedDate(value.end_date)}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </>
    );
  }

  render() {
    const { programs } = this.props;

    return (
      <div id='content'>
        <div className='pre-release-content'>
          <div className='express-lane-hero splunk-background splunk-black' id='banner-container' data-testid='banner-container-testid'>
            <div className='container'>
              <div>
                <div className='row'>
                  <div className='col-md-3 col-sm-12 col-xs-12 hero-voc-image'>
                    <img src='/voc-banner.svg' alt='Voice of the customer banner' />
                  </div>
                  <div className='col-md-9 col-sm-12 col-xs-12 hero-text'>
                    <p className='hero-voc-title padding-bottom-remove'>
                      Voice of the Customer
                    </p>
                    <p className='hero-voc-text'>Make your voice heard! Help us define, build, and launch new product capabilities.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='prerelease-content'>
            <div className='splunk-padding'>
              <div className='container'>
                <Tabs defaultTab={2}>
                  <Tab
                    label='Available Programs'
                    tabKey={1}
                    link={this.vocUrl}
                  />
                  <Tab
                    label='My Programs'
                    tabKey={2}
                  >
                    <div className='row'>
                      <div className='col-sm-12 col-md-12'>
                        <h3 className='welcome-title'>Welcome!</h3>
                        <div className='splunk-body'>
                          <p className='welcome-text'>
                            You have been approved to access the following programs. Thanks in advance
                            for your interest in trying out our programs and we look forward to your
                            feedback.
                          </p>
                        </div>
                      </div>
                    </div>

                    {this.renderPrograms(
                      programs.filter((program) => !program.is_public),
                      'There are no preview programs available at this time. Please check back with us soon.',
                      true
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProgramList.propTypes = {
  // eslint-disable-next-line react/require-default-props
  programs: PropTypes.array,
  clearProgramKey: PropTypes.func.isRequired,
  onSortPrograms: PropTypes.func.isRequired,
};
