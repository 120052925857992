import * as types from '../constants/actionTypes';
import { REQUEST, sendRequest } from '../utils/networkUtils';

export const createFeatureRequest = (data) => {
  return sendRequest(types.CREATE_FEATURE_REQUEST, REQUEST.POST, '/program/feedbacks', data);
};

export const fetchFeedbackTypes = () => {
  return sendRequest(types.FETCH_FEATURE_TYPES_REQUEST, REQUEST.GET, '/feedback/types');
};

export const fetchFeedbackFormData = () => {
  return sendRequest(types.FETCH_FEEDBACK_FORM_DATA_REQUEST, REQUEST.GET, '/feedback/data');
};
