interface Asset {
  path: string;
  size: number;
  filename: string;
  url: string;
}
interface OtherAssetsProps {
  assets: Array<Asset>;
}

const OtherAssets = ({ assets }: OtherAssetsProps) : JSX.Element => {
  const convertBytesToMb = (fileSize: number) => Math.round(fileSize / (1024 * 1024));
  const convertBytesToKb = (fileSize: number) => Math.round(fileSize / (1024));

 if (assets === undefined || assets === null || assets.length === 0 ) {
    return <></>
  }

  return (
    <div className='other-assets-list'>
    <table className='other-assets-table'>     
      <tbody>        
        {assets.map((asset) => {
          return (
            <tr key={asset.filename}>
              <td>
                <span className='filename strong'>{asset.filename}</span>
              </td>
              <td>
                <span className='filename strong'>{asset?.size > 1048576 ? `${convertBytesToMb(asset.size)} Mb` : `${convertBytesToKb(asset.size)} Kb`}</span>
              </td>
              <td style={{ textAlign: 'right' }}>
                <a
                  href={asset.url}
                  className='splunk-btn sp-btn-pink'
                  style={{ margin: '5px' }}
                  download
                >
                  <span className='glyphicon glyphicon-save' />
                  Download Now
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
  );
}

export default OtherAssets;
